import { Component, DestroyRef, inject } from '@angular/core';
import { CampaignFormService } from '../../campaign-form.service';
import { EstimationView, RecommendedBids, TargetItem, TargetMode } from '../../../../../../core/interface';
import { RolesService } from '../../../../../../core/services/roles.service';
import { CampaignsService } from '../../../campaigns/campaigns.service';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-campaign-form-sidebar',
  templateUrl: './campaign-form-sidebar.component.html',
  styleUrls: ['./campaign-form-sidebar.component.scss']
})
export class CampaignFormSidebarComponent {
  public isLoadingEstimate = false;
  public winRate = null;
  public recommendedBids: RecommendedBids = null;

  private destroyRef = inject(DestroyRef);

  constructor(
    public formService: CampaignFormService,
    public campaignsService: CampaignsService,
    public roles: RolesService,
    private globicaService: GlobicaService,
    private alerts: AdxadAlerts,
    private translate: TranslocoService
  ) {}

  /**
   * @return form control value of targeting by id
   * @param {string} id of targeting
   */
  getControlValue(id: string): string[] {
    return this.formService.form.get(`targetings.${id}.value`)?.value;
  }

  /**
   * @return {boolean} flag of show target by segment
   * @param {TargetItem} item
   */
  isShowTarget(item: TargetItem): boolean {
    const controlValue = this.getControlValue(item.id);

    if (Array.isArray(controlValue)) {
      return controlValue.length > 0;
    }

    if (controlValue && typeof controlValue === 'object') {
      return Object.values(controlValue).some(value => value !== null);
    }

    return false;
  }

  /**
   * @return {TargetMode} target mode: 'include' | 'exclude'
   * @param {TargetItem} item
   */
  getTargetMode(item: TargetItem): TargetMode {
    const targetMode = this.formService.targetings.get(item.id).value.mode ? 'include' : 'exclude';
    return this.translate.translate(targetMode);
  }

  /**
   * @return {boolean} show placements flag
   */
  get isShowPlacements(): boolean {
    return this.roles.isManagers() && this.formService.placements.get('value').value.length;
  }

  /**
   * Go to advanced step
   * Select target
   *
   * @param {TargetItem} item
   */
  goToTarget(item: TargetItem): void {
    this.formService.changeTarget(item.id);
    this.formService.changeStep('advanced');
  }

  /**
   * Go to placements step
   */
  goToPlacements(): void {
    this.formService.changeStep('placements');
  }

  /**
   * Onclick estimated amount of traffic
   */
  refreshEstimation(): void {
    this.isLoadingEstimate = true;
    const data = this.formService.targetings.value;
    data['mediaType'] = { value: this.formService.mediaType.value, mode: true };
    data['segment'] = { value: this.formService.segments.value, mode: true };
    data['placements'] = this.formService.placements.value;
    data['format'] = { value: this.formService.advertFormat.value, mode: true };
    data['costModel'] = this.formService.form.get('costModel').value;

    if (this.formService.isNewCampaign && this.formService.project.value) {
      data['projectId'] = this.formService.project.value;
    }

    this.campaignsService
      .getEstimation(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: EstimationView) => {
          this.isLoadingEstimate = false;
          this.winRate = result.data.estimator[0];
          this.recommendedBids = result.data.recommendedBids;

          const globicaParams = {
            estimate_amount: this.winRate
          };

          if (!this.formService.isNewCampaign) {
            data['campaignId'] = this.formService.campaignId.value;
            globicaParams['campaign_id'] = this.formService.campaignId.value;
          }

          /** Set estimation data */
          this.formService.estimatorData = {
            estimate_amount: this?.winRate,
            recommended_min: this.recommendedBids?.minCpm,
            recommended_mid: this.recommendedBids?.avgCpm,
            recommended_max: this.recommendedBids?.maxCpm
          };

          /** Track globica event */
          this.globicaService.trackEventGoals('buttonEstimate_click', globicaParams);
        },
        error: () => (this.isLoadingEstimate = false)
      });
  }

  /**
   * Set new bid in control;
   * Show warning
   *
   * @param {number} value - new bid value
   */
  public setBid(value: number): void {
    if (this.formService.activeStep !== 'pricing') {
      return;
    }

    const control = this.formService.form.get('bid');
    const oldBid = control.value || 0;
    control.setValue(value);

    this.alerts.warning(this.translate.translate('alert_bidWasChanged', { oldBid, value }), 5000);
  }

  /**
   * If media type Banner or HTML, view recommended bids
   */
  get isViewRecommendedBids(): boolean {
    return this.recommendedBids && (this.formService.mediaType.value === 'image' || this.formService.mediaType.value === 'html');
  }
}
