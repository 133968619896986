import { Component, OnInit } from '@angular/core';
import { FormatBidPipe } from '../../../../../../core/pipes/format-bid.pipe';
import { CampaignFormService } from '../../campaign-form.service';
import { AdxadLabelTabIconStatus, CampaignFormStep } from '../../../../../../core/interface';
import { RolesService } from '../../../../../../core/services/roles.service';
import { PushLiveTimeValidityService } from '../push-live-time/push-live-time-validity.service';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss'],
  providers: [FormatBidPipe, CampaignFormService]
})
export class CampaignFormComponent implements OnInit {
  constructor(
    public fs: CampaignFormService,
    public roles: RolesService,
    private isPushLiveTimeValid: PushLiveTimeValidityService
  ) {}

  ngOnInit(): void {
    this.fs.init();
  }

  /**
   * Disable stepper
   *
   * @param {string} direct
   */
  isStepperBtnDisabled(direct: string): boolean {
    const index = this.fs.steps.findIndex(x => x.id === this.fs.activeStep);
    const isGeneralStep = this.fs.steps.find(x => x.id === 'general');

    if (direct === 'prev') {
      return index === 0 || this.isNotValidStep(isGeneralStep);
    }

    if (direct === 'next') {
      return index === this.fs.steps.length - 1 || this.isNotValidStep(isGeneralStep, true);
    }

    return false;
  }

  /**
   * Steps 1 & 2 - valid of controls
   * Step 3 must be selected minimum one
   *
   * @return {boolean} validation of step
   * @param {CampaignFormStep} step
   * @param {boolean} ignoreTouched need for valid Save btn
   */
  public isNotValidStep(step: CampaignFormStep, ignoreTouched?: boolean): boolean {
    if (!ignoreTouched && !step.touched) {
      return false;
    }

    if (step.id === 'general') {
      const controls = ['name', 'project', 'mediaType', 'format', 'segments'];
      return !!controls.filter(x => this.fs.form.get(x).invalid).length;
    }

    if (step.id === 'clickUrl') {
      const clickUrlControl = this.fs.form.get('clickUrl');
      return !clickUrlControl.disabled && clickUrlControl.invalid;
    }

    if (step.id === 'pricing') {
      let budgetControls = ['freqCap', 'bid', 'budget.dailyCap', 'budget.totalBudget'];
      let smartCpmControls = ['smartCpm.optStrategy', 'smartCpm.targetValue'];
      const smartCpmManagersControls = ['smartCpm.segment', 'smartCpm.testWindow'];

      if (this.fs.isSmartBidVisible && this.fs.smartBid.value === true) {
        budgetControls = [...budgetControls, ...smartCpmControls];
        if (this.roles.isManagers()) {
          budgetControls = [...budgetControls, ...smartCpmManagersControls];
        }
      }

      if (this.roles.isManagers()) {
        if (this.fs.form.get('enableCustomCommission').value === true) {
          budgetControls = [...budgetControls, 'customCommission'];
        }
      }

      return !!budgetControls.filter(x => this.fs.form.get(x).invalid).length;
    }

    if (step.id === 'advanced') {
      return this.isPushLiveTimeValid.isPushLiveTimeValid$.getValue();
    }

    return false;
  }

  /**
   * @returns {boolean} check valid of steps
   */
  isDisableSaveBtn(): boolean {
    return !!this.fs.steps.filter(x => this.isNotValidStep(x, true)).length;
  }

  /**
   * @return {boolean} flag to show success in steps
   * @param {CampaignFormStep} step
   */
  isSuccessStep(step: CampaignFormStep): boolean {
    if (!step.touched) {
      return false;
    }
    return !this.isNotValidStep(step);
  }

  /**
   * @return {string} step validation status
   * @param {CampaignFormStep} step
   */
  getStepStatus(step: CampaignFormStep): AdxadLabelTabIconStatus {
    if (this.isNotValidStep(step)) {
      return 'invalid';
    }

    if (this.isSuccessStep(step)) {
      return 'success';
    }

    return null;
  }
}
