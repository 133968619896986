import { DestroyRef, Injectable } from '@angular/core';
import {
  BidCheckRequest,
  BidMinMax,
  BidMinMaxResponse,
  BlackListData,
  CampaignFormStep,
  CampaignFormStepType,
  CostModelRule,
  Dicts,
  EntityResponse,
  LazyLoadDict,
  Segment,
  SegmentGroup,
  SegmentsConfig,
  TargetItem
} from '../../../../core/interface';
import { DspService } from '../../../../core/services/dsp.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesService } from '../../../../core/services/roles.service';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { APP_ROUTE, DESK_ROUTE } from '../../../../core/routes';
import { CampaignsService } from '../campaigns/campaigns.service';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { debounceTime, distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { AdxadValidators } from '../../../../core/validators';
import { CampaignFormApiService } from './campaign-form-api.service';
import { GlobicaService } from '../../../../core/services/globica.service';
import { TranslocoService } from '@jsverse/transloco';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { NavigationService } from '../../../../core/services/navigation.service';
import { CampaignViewComponent } from '../campaigns/components/campaign-view/campaign-view.component';
import { DictsService } from '../../../../core/services/dicts.service';
import {
  BANNER_FILTER_TYPES,
  BANNER_REDIRECT,
  CLICK_FILTER_TYPES,
  REDIRECT_TYPES,
  SMART_CPM_SEGMENTS,
  SMART_CPM_STRATEGY
} from '../../../../core/configs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class CampaignFormService {
  public steps = [
    {
      id: 'general',
      name: 'step_general',
      touched: false,
      globicaKey: 'tabGeneral_click'
    },
    {
      id: 'clickUrl',
      name: 'step_clickUrl',
      touched: false,
      globicaKey: 'tabUrl_click'
    },
    {
      id: 'advanced',
      name: 'step_advanced',
      touched: false,
      globicaKey: 'tabAdvanced_click'
    },
    {
      id: 'placements',
      name: 'step_spots',
      touched: false,
      globicaKey: 'tabSpots_click'
    },
    {
      id: 'pricing',
      name: 'step_pricingAndLimits',
      touched: false,
      globicaKey: 'tabPricing_click'
    }
  ] as CampaignFormStep[];

  public activeStep: CampaignFormStepType = 'general';
  public targetingsConfig = [] as TargetItem[];

  public segmentsConfig = {
    data: [],
    defaultControls: []
  } as SegmentsConfig;

  public form: UntypedFormGroup;
  public id: string;
  public dict = {
    redirectTypes: { data: [...REDIRECT_TYPES] },
    clickFilterTypes: { data: [...CLICK_FILTER_TYPES] },
    bannerRedirect: { data: [...BANNER_REDIRECT] },
    bannerFilterTypes: { data: [...BANNER_FILTER_TYPES] },
    mediaTypes: { data: [] },
    optStrategy: { data: [...SMART_CPM_STRATEGY] },
    smartCpmSegments: { data: [...SMART_CPM_SEGMENTS] }
  } as Dicts;

  public isLoading = {
    main: false,
    blackList: false,
    bidValidators: false,
    segments: false,
    cashDicts: false,
    mediaType: false
  };

  public projectId: string;
  public bidLabel = 'label_bidAmount';
  public archivedSegments = [] as Segment[];
  public isTargetChanged = {};
  public isShowCostModelChangedInfo = false;
  public estimatorData = {}; // For globica service
  public isDynamicBidVisible: boolean = false;
  public isCampaignTtlVisible: boolean = false;
  public isSmartBidVisible: boolean = false;
  public commission: number = null;

  private isInitedForm: boolean = false;

  constructor(
    private queryParamsService: QueryParamsService,
    private campaignService: CampaignsService,
    public navigationService: NavigationService,
    private dspService: DspService,
    private dictsService: DictsService,
    private formApi: CampaignFormApiService,
    private alerts: AdxadAlerts,
    private route: ActivatedRoute,
    private router: Router,
    public roles: RolesService,
    private fb: UntypedFormBuilder,
    private globica: GlobicaService,
    private translate: TranslocoService,
    private sidebarModal: AdxadSidebarModal,
    private destroyRef: DestroyRef
  ) {}

  /**
   * Init campaign form service
   * Check id in url
   * Load data
   */
  init(): void {
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.projectId = this.queryParamsService.get()['projectId'];

    if (!this.isNewCampaign) {
      this.steps.forEach(x => (x.touched = true));
    }

    /** disable spots step for noon managers */
    if (!this.roles.isManagers() && !this.roles.isInternalBuyer()) {
      this.steps.splice(
        this.steps.findIndex(x => x.id === 'placements'),
        1
      );
    }

    this.createForm();
    this.setSubscribes();
    this.trackBudgetControlsStatus();

    /**
     * For create form, first load dicts, then load configs
     * For non managers roles configs load on init
     * For managers & admins roles configs load after project select
     *
     * For edit form, first load configs, then load dicts
     */
    if (this.isNewCampaign) {
      this.loadDictsForCreateForm();

      if (this.projectId || !this.roles.isManagers()) {
        this.loadNewFormSettings();
      }
    } else {
      this.loadEditFormSettings();
    }
  }

  /**
   * Load new form settings
   * Get targetings config, segments config, commission
   * If is admins or managers sent this request on change project
   * If is non manager role, sent this request on init form
   * Set configs
   */
  loadNewFormSettings(): void {
    this.isLoading.segments = true;

    const data = {};
    if (this.roles.isManagers()) {
      data['projectId'] = this.form.get('project').value;
    }

    this.formApi
      .getNewFormSettings(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ status, data }) => {
          if (status === 'OK') {
            const { targetConfig, segmentSettings, finances } = data;

            if (targetConfig) {
              this.setTargetingsConfig(targetConfig);
            }

            if (finances) {
              this.commission = finances.commission;
            }

            if (segmentSettings) {
              this.isLoading.segments = false;
              this.setSegmentsConfig(segmentSettings);
            }
          }
        },
        error: () => {
          this.isLoading.segments = false;
        }
      });
  }

  /**
   * Load edit form settings
   * Get form data, targetings config, segments config, commission, bids
   * Set configs
   * Load dicts
   */
  loadEditFormSettings(): void {
    if (this.isLoading.main) {
      return;
    }
    this.isLoading.main = true;

    this.formApi
      .getEditFormSettings({ id: this.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ status, data }) => {
          if (status === 'OK') {
            const { campaign, targetConfig, segmentSettings, finances } = data;

            if (!campaign || !targetConfig || !segmentSettings) {
              this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
              this.goBack();
            }

            if (targetConfig) {
              this.setTargetingsConfig(targetConfig);
            }

            if (finances) {
              this.commission = finances.commission;

              if (this.roles.isManagers()) {
                this.form.get('enableCustomCommission').patchValue(finances.enableCustomCommission);
                this.form.get('customCommission').patchValue(finances.customCommission);
              }

              if (finances.bids) {
                this.setBidValidators(finances.bids);
              }
            }

            if (segmentSettings) {
              this.isLoading.segments = false;
              this.setSegmentsConfig(segmentSettings);
            }

            if (campaign) {
              this.form.patchValue(campaign);
            }

            this.isLoading.main = false;
            this.loadDictsForEditForm();
          }
        },
        error: () => this.goBack()
      });
  }

  /**
   * Load dicts for create form
   * Cash media types, advert format & holders dicts
   */
  loadDictsForCreateForm(): void {
    if (this.isLoading.main) {
      return;
    }
    this.isLoading.main = true;

    const request = {
      limit: 200,
      page: 1
    };

    const projectRequest = {
      limit: 200,
      page: 1,
      ids: [this.projectId]
    };

    const formatCashId = 'campFormGeneralStepFormatForCreate';
    const formatMethod = this.dictsService.isCashed(formatCashId)
      ? this.dictsService.getDict$(formatCashId, request)
      : this.dspService.getAdvFormatsForCreate(request);

    const holdersCashId = 'campFormGeneralStepHolders';
    const holdersMethod = this.dictsService.isCashed(holdersCashId)
      ? this.dictsService.getDict$(holdersCashId, request)
      : this.dspService.getPlaceholders(request);

    const forkList = [this.dspService.getProjectsDict(this.projectId ? projectRequest : request), holdersMethod, formatMethod];

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([projects, holders, advertFormat]: LazyLoadDict[]) => {
          this.dict.projects = projects;

          if (!this.dict.projects.data.length) {
            this.alerts.warning(this.translate.translate('alert_youHaveNotAnyProjectsYet'));
            this.router.navigateByUrl(APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.projects);
            return;
          }

          if (this.roles.isAffiliate()) {
            /** preset project */
            this.project.setValue(this.dict.projects.data[0].id);
            this.project.updateValueAndValidity();
          }

          if (this.project.value) {
            this.updateMediaTypeDict(this.project.value);
            this.loadUserFeatures(this.project.value);
          }

          this.dict.advertFormat = advertFormat;

          this.dict.holders = holders;
          this.dict.holders.data.forEach(x => (x.selected = false));

          /** set media type, advert format & holders dicts in cash service */
          this.dictsService.setDict(formatCashId, advertFormat);
          this.dictsService.setDict(holdersCashId, holders);

          this.isLoading.main = false;
        },
        error: () => this.goBack()
      });
  }

  /**
   * Load dictionaries:
   * Projects, Advert format, Media types, Placeholders (for click url)
   * cash holders dict
   */
  loadDictsForEditForm(): void {
    if (this.isLoading.main) {
      return;
    }
    this.isLoading.main = true;

    const request = {
      limit: 200,
      page: 1
    };

    const holdersCashId = 'campFormGeneralStepHolders';
    const holdersMethod = this.dictsService.isCashed(holdersCashId)
      ? this.dictsService.getDict$(holdersCashId, request)
      : this.dspService.getPlaceholders(request);

    const forkList = [
      this.dspService.getProjectsDict({ ...request, ids: [this.form.get('project').value] }),
      holdersMethod,
      this.dspService.getMediaTypesForEdit({ ...request, includes: [this.form.get('mediaType').value] })
    ];

    if (this.form.get('format').value?.length) {
      forkList.push(this.dspService.getAdvFormatsForEdit({ ...request, includes: [this.form.get('format').value] }));
    }

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([projects, holders, mediaTypes, advertFormat]: LazyLoadDict[]) => {
          this.isLoading.main = false;

          this.dict.projects = projects;

          if (!this.dict.projects.data.length) {
            this.alerts.warning(this.translate.translate('alert_youHaveNotAnyProjectsYet'));
            this.router.navigateByUrl(APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.projects);
            return;
          }

          this.dict.mediaTypes = mediaTypes;

          this.dict.holders = holders;
          this.dict.holders.data.forEach(x => (x.selected = false));

          if (advertFormat) {
            this.dict.advertFormat = advertFormat;
          }

          /** set holders dicts in cash service */
          this.dictsService.setDict(holdersCashId, holders);
        },
        error: () => this.goBack()
      });
  }

  /**
   * Change active step
   * Set touched in step for validation
   *
   * @param {CampaignFormStepType} id
   */
  changeStep(id: CampaignFormStepType): void {
    if (this.activeStep === id) {
      return;
    }

    this.setTouched();
    this.activeStep = id;

    /** Track globica event */
    const globicaKey = this.steps.find((x: CampaignFormStep) => x.id === this.activeStep).globicaKey;
    this.globica.trackEventGoals(globicaKey, !this.isNewCampaign ? { campaign_id: this.campaignId?.value } : null);
  }

  /**
   * Go to prev step
   */
  prevStep(): void {
    let index = this.steps.findIndex(x => x.id === this.activeStep);

    if (index === 0) {
      return;
    }

    index--;
    while (this.isDisabledStep(this.steps[index].id)) {
      index--;
    }

    /** Track globica event */
    const step_name = this.translate.translate(this.steps[index].name, {}, 'en');
    this.globica.trackEventGoals(
      'buttonPrevious_click',
      !this.isNewCampaign ? { step_name, campaign_id: this.campaignId.value } : { step_name }
    );
    this.changeStep(this.steps[index].id);
  }

  /**
   * Go to next step
   */
  nextStep(): void {
    let index = this.steps.findIndex(x => x.id === this.activeStep);
    if (index === this.steps.length - 1) {
      return;
    }

    index++;

    while (this.isDisabledStep(this.steps[index].id)) {
      index++;
    }
    /** Track globica event */
    const step_name = this.translate.translate(this.steps[index].name, {}, 'en');
    this.globica.trackEventGoals(
      'buttonNext_click',
      !this.isNewCampaign ? { step_name, campaign_id: this.campaignId.value } : { step_name }
    );

    this.changeStep(this.steps[index].id);
  }

  /**
   * If mediaType is pops or html, step 2 is disabled
   *
   * @param {CampaignFormStepType} id
   * @return {boolean} flag for disable step
   */
  isDisabledStep(id: CampaignFormStepType): boolean {
    const generalStepControls = ['name', 'project', 'mediaType', 'format', 'segments'];

    if (id !== 'general' && generalStepControls.filter(x => this.form.get(x).invalid).length) {
      return true;
    }

    if (id === 'clickUrl') {
      return this.form.get('clickUrl').disabled;
    }

    return false;
  }

  /**
   * Set touched controls in step for visualise invalid fields
   */
  setTouched(): void {
    const step = this.steps.find((x: CampaignFormStep) => x.id === this.activeStep);
    if (!step || step.touched) {
      return;
    }

    switch (step.id) {
      case 'general': {
        this.form.get('name').markAsTouched();
        this.form.get('project').markAsTouched();
        this.form.get('mediaType').markAsTouched();
        this.form.get('format')?.markAsTouched();
        break;
      }
      case 'clickUrl': {
        this.form.get('clickUrl').markAsTouched();
        break;
      }
      case 'pricing': {
        this.form.get('bid').markAsTouched();
        this.form.get('budget.totalBudget').markAsTouched();
        this.form.get('budget.dailyCap').markAsTouched();
        this.form.get('freqCap').markAsTouched();
        break;
      }
    }

    step.touched = true;
  }

  /**
   * @return {boolean} is new campaign flag
   */
  get isNewCampaign(): boolean {
    return !this.id;
  }

  /**
   * Set targetings config
   *
   * @param {TargetItem} config
   */
  setTargetingsConfig(config: TargetItem[]): void {
    this.targetingsConfig = config;
    this.targetingsConfig.forEach((item: TargetItem) => {
      item.isSelected = false;

      if (item.type === 'dict') {
        item.dicts = {
          available: {
            dict: { data: [] },
            isLazy: false,
            excludes: [],
            isLoading: false
          },
          selected: {
            dict: { data: [] },
            isLazy: false,
            includes: [],
            isLoading: false
          }
        };

        if (!this.dictsService.isDynamicDict(item.id)) {
          item.dicts.cashId = 'campFormTargetings' + item.id;
        }
      }

      this.targetings.addControl(item.id, this.fb.group({ value: [[]], mode: true }));

      if (item.rule) {
        if (typeof item.rule !== 'object' || Array.isArray(item.rule) || !Object.keys(item.rule).length) {
          /** Delete rule if is not object or if it is empty */
          delete item.rule;
        } else {
          /** create rules observes for clean disabled targets*/
          this.subscribeRule(item);
        }
      }
    });

    this.loadCashDicts();
    this.targetingsConfig[0].isSelected = true;
  }

  /**
   * Create rules observes for clean disabled targets
   * @param item
   */
  subscribeRule(item: TargetItem): void {
    Object.entries(item.rule).forEach(([key, rule]) => {
      const control = this.form.get(key);
      if (!control) {
        return;
      }

      /** create observe */
      this.form
        .get(key)
        .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: value => {
            const target = this.getTargetControl(`${item.id}.value`);
            if (!target || !target.value || !target.value.length) {
              return;
            }

            const isNeedClearTarget =
              (typeof rule === 'string' && value.indexOf(rule) === -1) || (Array.isArray(rule) && !rule.find(x => value.indexOf(x) !== -1));

            if (isNeedClearTarget) {
              target.setValue([]);
            }
          }
        });
    });
  }

  /**
   * Load & set cash dicts
   */
  loadCashDicts(): void {
    this.isLoading.cashDicts = true;
    const forkList = [];

    const nonCashDicts = this.targetingsConfig.filter(
      (item: TargetItem) => item.dicts?.cashId && !this.dictsService.isCashed(item.dicts.cashId)
    );

    nonCashDicts.forEach((item: TargetItem) =>
      forkList.push(
        this.dspService.getDictByUrl(item.url, {
          limit: 500,
          page: 1
        })
      )
    );

    if (!forkList.length) {
      this.isLoading.cashDicts = false;
      return;
    }

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: LazyLoadDict[]) => {
        result.forEach((dict, i) => {
          this.dictsService.setDict(nonCashDicts[i].dicts.cashId, dict);
        });
        this.isLoading.cashDicts = false;
      });
  }

  /**
   * Set segments config
   *
   * If is new campaign, set default controls
   * If default controls not exist in segments, set first segment
   *
   * If is edit form check segments in config & form
   * If segments in form is archived, remove it from form and add to archivedSegments
   *
   * clear spots
   *
   * set cost model
   *
   * @param {SegmentsConfig} config
   */
  setSegmentsConfig(config: SegmentsConfig): void {
    this.segmentsConfig = config;

    if (this.isNewCampaign) {
      /** check default controls existing in config */
      const controls = [];
      this.segmentsConfig.data.forEach(x => controls.push(...x.segments.map(x => x.id))); // flat controls
      const defaultControlsIds = this.segmentsConfig.defaultControls.filter(id => controls.indexOf(id) !== -1);
      const defaultValue = defaultControlsIds || [controls[0]];
      this.segments.setValue(defaultValue);

      /** Clear spots */
      const spotsControl = this.getTargetControl('spots.value');
      if (spotsControl.value.length) {
        spotsControl.setValue([]);
      }
    } else {
      /** If segments in form is archived */
      this.segments.value.forEach((id, i) => {
        this.segmentsConfig.data.forEach((group: SegmentGroup) => {
          group.segments.forEach((segment: Segment) => {
            if (segment.id === id && segment.status === 0) {
              this.segments.value.splice(i, 1);
              this.archivedSegments.push(segment);
            }
          });
        });
      });
    }

    /** set cost model */
    const group = this.getActiveSegmentsGroup(this.segments.value[0]);
    if (group) {
      this.setCostModel(group);
    }

    this.isInitedForm = true;
  }

  /**
   * @return {SegmentGroup} active segments group
   * @param {string} id
   */
  getActiveSegmentsGroup(id: string): SegmentGroup {
    return this.segmentsConfig.data.find((group: SegmentGroup) => {
      return group.segments.find(segment => segment.id === id);
    });
  }

  /**
   * Set cost model & bid label
   *
   * @param { SegmentGroup } segmentGroup
   */
  setCostModel(segmentGroup: SegmentGroup): void {
    if (!segmentGroup || !segmentGroup.costModel) {
      return;
    }

    const costModelControl = this.form.get('costModel');
    let label = segmentGroup.costModel.label;
    let model = segmentGroup.costModel.id;

    if (segmentGroup.costModelRule?.length) {
      segmentGroup.costModelRule.forEach((rule: CostModelRule) => {
        const control = rule.control;
        const value = rule.value;

        if (this.form.get(control).value === value) {
          label = rule.costModel.label;
          model = rule.costModel.id;
        }
      });
    }

    if (model !== costModelControl.value && this.isInitedForm) {
      const bidControl = this.form.get('bid');
      bidControl.setValue('');

      if (!this.isNewCampaign || bidControl.touched) {
        this.isShowCostModelChangedInfo = true;
      }
    }

    costModelControl.setValue(model);
    this.bidLabel = label;
  }

  /**
   * @return {FormControl} campaign id reactive form control
   */
  get smartBid(): FormControl {
    return this.form.get('smartBid') as FormControl;
  }

  /**
   * @return observable form control changes by id
   * @param {string} id
   */
  getControlChanges(id: string): Observable<any> {
    return this.form.get(id).valueChanges.pipe(takeUntilDestroyed(this.destroyRef));
  }

  /**
   * Track globica event if budgets controls invalid
   */
  trackBudgetControlsStatus(): void {
    const controls = ['budget.dailyCap', 'budget.totalBudget', 'bid', 'freqCap'];
    const globicaKeys = {
      bid: 'formCampaignBidAmountInput_change',
      freqCap: 'formCampaignFrequencyCapInput_change',
      'budget.dailyCap': 'formCampaignDailyBudgetInput_change',
      'budget.totalBudget': 'formCampaignTotalBudgetInput_change'
    };

    controls.filter(x => {
      this.form
        .get(x)
        .statusChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
        .subscribe(status => {
          /** Track globica event */
          if (status === 'INVALID') {
            this.globica.trackEventGoals(
              globicaKeys[x],
              !this.isNewCampaign ? { is_valid: 0, campaign_id: this.campaignId.value } : { is_valid: 0 }
            );
          }
        });
    });
  }

  /**
   * @return {FormControl} smartCpmGroup
   */
  get smartCpmGroup(): FormGroup {
    return this.form.get('smartCpm') as FormGroup;
  }

  /**
   * Update media type by project id, result should be returned with user settings.
   * If the Push flag is enabled, the result should be returned with push format.
   * @param projectId
   */
  updateMediaTypeDict(projectId: string) {
    if (this.isLoading.mediaType) {
      return;
    }
    this.isLoading.mediaType = true;

    const request = {
      limit: 200,
      page: 1,
      id: projectId
    };

    this.dspService
      .getMediaTypesFoCreateByProject(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: mediaTypes => {
          this.isLoading.mediaType = false;
          this.dict.mediaTypes = mediaTypes;
          if (!this.isNewCampaign) {
            this.mediaType.patchValue(this.dict.mediaTypes.data[0].id);
            this.mediaType.updateValueAndValidity();
          }
        },
        error: () => (this.isLoading.mediaType = false)
      });
  }

  /**
   * Create campaign reactive form
   * Create targetings by dict
   * If is edit form, add id control & disable project, mediaType & format, load bid validators
   */
  createForm(): void {
    const scheduleValue = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      project: ['', [Validators.required]],
      format: ['', Validators.required],
      mediaType: ['', Validators.required],
      freqCap: [99, [Validators.required, Validators.min(1), Validators.max(99), AdxadValidators.integer]],
      clickUrl: ['https://', [Validators.required, AdxadValidators.clickUrl]],
      bid: ['', [Validators.required, Validators.min(0.001), AdxadValidators.maxLengthAfterDot(4), AdxadValidators.maxLengthNumber(7)]],
      categories: [[]],
      schedule: [
        {
          mon: [...scheduleValue],
          tue: [...scheduleValue],
          wed: [...scheduleValue],
          thu: [...scheduleValue],
          fri: [...scheduleValue],
          sat: [...scheduleValue],
          sun: [...scheduleValue]
        }
      ],
      targetings: this.fb.group({}),
      budget: this.fb.group({
        dailyCap: [0, [Validators.min(0), Validators.max(99999.99), Validators.maxLength(8)]],
        totalBudget: [0, [Validators.min(0), Validators.max(99999.99), Validators.maxLength(8)]],
        hourlyCapEnable: false
      }),
      segments: [[], Validators.minLength(1)],
      costModel: 'cpm',
      placements: this.fb.group({
        mode: true,
        value: [[]]
      }),
      dynamicBid: false,
      smartBid: false,
      smartCpm: this.fb.group({
        optStrategy: ['clicks', [Validators.required]],
        targetValue: [0.01, [Validators.required, Validators.min(0.01), Validators.max(100)]]
      }),
      ttl: this.fb.control(0)
    });

    if (this.roles.isManagers()) {
      this.smartCpmGroup.addControl('testWindow', this.fb.control(1000, [Validators.required, Validators.min(1000)]));
      this.smartCpmGroup.addControl('segment', this.fb.control(['spot', 'country'], [Validators.required]));
      this.form.addControl('enableCustomCommission', this.fb.control(false));
      this.form.addControl('customCommission', this.fb.control(null));
    }

    if (!this.isNewCampaign) {
      this.form.addControl('id', this.fb.control(this.id));
      this.form.get('project').disable();
      this.form.get('mediaType').disable();
      this.form.get('format').disable();
    }

    if (this.roles.isSuperAdmin()) {
      this.form.addControl(
        'antifraud',
        this.fb.group({
          redirectType: REDIRECT_TYPES[0].id,
          clickFilterType: CLICK_FILTER_TYPES[0].id
        })
      );
    }
  }

  /**
   * Get block list of SSPs by project id & media type
   * Not load if SSP target was changed by user
   */
  loadBlackListSSPs(): void {
    combineLatest([this.getControlChanges('mediaType'), this.getControlChanges('project')])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(([mediaType, projectId]) => mediaType && projectId),
        tap(() => (this.isLoading.blackList = true)),
        switchMap(([mediaType, projectId]) => this.dspService.getBlackList({ mediaType, projectId }))
      )
      .subscribe({
        next: ({ data }: BlackListData) => {
          if (!data) {
            return;
          }

          Object.entries(data).forEach(([key, values]) => {
            if (!this.isTargetChanged[key]) {
              const control = this.getTargetControl(key);

              if (control) {
                control.get('mode').setValue(values['mode']);
                control.get('value').setValue(values['values']);
              }
            }
          });

          this.isLoading.blackList = false;
        },
        error: () => (this.isLoading.blackList = false)
      });
  }

  /**
   * Validation of the minimum and maximum values for the bid.
   */
  checkBid(): void {
    combineLatest([
      this.getControlChanges('mediaType'),
      this.segments.valueChanges.pipe(debounceTime(300)),
      this.getControlChanges('costModel')
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => (this.isLoading.bidValidators = true)),
        map(([mediaType, segments, costModel]) => ({ segments, mediaType, costModel }) as BidCheckRequest),
        switchMap((request: BidCheckRequest) => {
          if (!this.isNewCampaign) {
            request['campaignId'] = this.campaignId.value;
          }
          return this.campaignService.bidCheck(request);
        })
      )
      .subscribe({
        next: ({ data, status }: BidMinMaxResponse) => {
          if (status === 'OK') {
            this.setBidValidators(data);
          }

          this.isLoading.bidValidators = false;
        },
        error: () => (this.isLoading.bidValidators = false)
      });
  }

  /**
   * Set bid min & max validation
   * @param {number} min
   * @param {number} max
   */
  setBidValidators({ min, max }: BidMinMax): void {
    this.bid.clearValidators();
    this.bid.addValidators([
      Validators.required,
      AdxadValidators.maxLengthAfterDot(4),
      Validators.maxLength(7),
      Validators.min(min),
      Validators.max(max)
    ]);
    this.bid.updateValueAndValidity();
  }

  /**
   * Disable advert format control for pops media type & enable for other
   * Toggle validators in clockUrl for html media type
   *
   * @param {string} value
   */
  checkType(value: string): void {
    const format = this.form.get('format');
    const clickUrl = this.form.get('clickUrl');

    if (value === 'pops' || value === 'push' || value === 'native' || value === 'interstitial' || value === 'vast') {
      format.setValue('');
      format.disable();
    } else if (format.disabled && this.isNewCampaign) {
      format.enable();
    }

    if (value === 'html' || value === 'pops' || value === 'interstitial') {
      clickUrl.setValue('');
      clickUrl.disable();
    } else {
      clickUrl.setValue('https://');
      clickUrl.enable();
    }
    clickUrl.updateValueAndValidity();
  }

  /**
   * Disable & clear advert format control for pops media type & enable for other
   * Toggle validators in clockUrl for html media type
   *
   * @param {string} value
   */
  checkFormat(value: string): void {
    const mediaType = this.form.get('mediaType');

    if (value === 'pops' || value === 'push' || value === 'native' || value === 'interstitial' || value === 'vast') {
      mediaType.setValue(value);
    }
  }

  /**
   * Clear placements value
   */
  removePlacements(): void {
    this.form.get('placements.value').setValue([]);
  }

  /**
   * @return {UntypedFormGroup} segments reactive form group
   */
  get segments(): UntypedFormGroup {
    return this.form.get('segments') as UntypedFormGroup;
  }

  /**
   * @return {UntypedFormGroup} placements reactive form group
   */
  get placements(): UntypedFormGroup {
    return this.form.get('placements') as UntypedFormGroup;
  }

  /**
   * @return {UntypedFormGroup} targetings reactive form group
   */
  get targetings(): UntypedFormGroup {
    return this.form.get('targetings') as UntypedFormGroup;
  }

  /**
   * @return target form group by id
   * @param id
   */
  getTargetControl(id: string): UntypedFormGroup {
    return this.targetings.get(id) as UntypedFormGroup;
  }

  /**
   * @return {UntypedFormGroup} click url reactive form control
   */
  get clickUrl(): UntypedFormControl {
    return this.form.get('clickUrl') as UntypedFormControl;
  }

  /**
   * @return {UntypedFormGroup} bid reactive form control
   */
  get bid(): UntypedFormControl {
    return this.form.get('bid') as UntypedFormControl;
  }

  /**
   * @return {UntypedFormGroup} mediaType reactive form control
   */
  get mediaType(): UntypedFormControl {
    return this.form.get('mediaType') as UntypedFormControl;
  }

  /**
   * @return {UntypedFormGroup} advert format reactive form control
   */
  get advertFormat(): UntypedFormControl {
    return this.form.get('format') as UntypedFormControl;
  }

  /**
   * @return {UntypedFormGroup} project reactive form control
   */
  get project(): UntypedFormControl {
    return this.form.get('project') as UntypedFormControl;
  }

  /**
   * @return {UntypedFormGroup} campaign id reactive form control
   */
  get campaignId(): UntypedFormControl {
    return this.form.get('id') as UntypedFormControl;
  }

  /**
   * Set form subscribes
   * mediaType: remove placements, load bid validators, set advert format, set cost model, load black list SSPs
   * format: remove placements, set media type;
   * project: for managers load segment config, load black list SSPs
   * segments: remove placements, load bid validators,
   * dailyCap: set hourlyCapEnable
   */
  setSubscribes(): void {
    /** create load blacklist SSPs for new camp */
    if (this.isNewCampaign) {
      this.loadBlackListSSPs();
    }

    this.getControlChanges('bid')
      .pipe(distinctUntilChanged())
      .subscribe(() => (this.isShowCostModelChangedInfo = false));

    this.getControlChanges('mediaType').subscribe(value => {
      if (this.roles.isSuperAdmin()) {
        const antifraud = this.form.get('antifraud') as UntypedFormGroup;
        if (value === 'native' || value === 'image' || value === 'push' || value === 'vast') {
          antifraud.addControl('bannerRedirect', this.fb.control(BANNER_REDIRECT[0].id));
          antifraud.addControl('bannerFilterType', this.fb.control(BANNER_FILTER_TYPES[0].id));
        } else {
          antifraud.removeControl('bannerRedirect');
          antifraud.removeControl('bannerFilterType');
        }
      }

      /** Track globica event */
      if (value) {
        const media_type = value;
        const campaign_id = this.campaignId?.value;

        this.globica.trackEventGoals(
          'selectMediaType_select',
          !this.isNewCampaign
            ? {
                media_type,
                campaign_id
              }
            : { media_type }
        );
      }

      this.removePlacements();
      this.checkType(value);

      const group = this.getActiveSegmentsGroup(this.segments.value[0]);
      if (group) {
        this.setCostModel(group);
      }
    });

    this.getControlChanges('format').subscribe(value => {
      /** Track globica event */
      if (value) {
        const globicaParams = {
          media_format: value
        };
        if (!this.isNewCampaign) {
          globicaParams['campaign_id'] = this.campaignId.value;
        }
        this.globica.trackEventGoals('selectMediaFormat_select', globicaParams);
      }
      this.removePlacements();
      this.checkFormat(value);
    });

    this.getControlChanges('project').subscribe(value => {
      if (value) {
        if (this.isNewCampaign) {
          /** Reload segments config for managers */
          if (this.roles.isManagers()) {
            this.loadNewFormSettings();
          }

          /** Reload media types dict */
          this.updateMediaTypeDict(value);
        }

        this.loadUserFeatures(value);
      }
    });

    if (this.projectId) {
      this.project.patchValue(this.projectId);

      if (this.isNewCampaign && this.queryParamsService.get()['projectId']) {
        this.project.disable();
      }

      this.loadUserFeatures(this.projectId);
    }

    this.segments.valueChanges.pipe(skip(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.removePlacements();
      const spotsTarget = this.getTargetControl('spots');
      if (spotsTarget) {
        spotsTarget.get('value').setValue([]);
      }
    });

    this.getControlChanges('budget.dailyCap').subscribe(value => {
      const hourlyCapEnable = this.form.get('budget.hourlyCapEnable');

      if (value > 0) {
        hourlyCapEnable.enable();
      } else {
        hourlyCapEnable.setValue(false);
        hourlyCapEnable.disable();
      }

      hourlyCapEnable.updateValueAndValidity();
    });

    this.getControlChanges('budget.hourlyCapEnable')
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe(value => {
        this.globica.trackEventGoals(
          value ? 'checkboxDistribute_check' : 'checkboxDistribute_uncheck',
          !this.isNewCampaign ? { campaign_id: this.campaignId?.value } : null
        );
      });

    /** Track globica event */
    this.form.statusChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe(status => {
      if (this.isNewCampaign && status === 'VALID') {
        this.globica.trackEventGoals('buttonCreateCreative_enable');
        this.globica.trackEventGoals('buttonCreateCampaign_enable');
      }
    });

    this.getControlChanges('dynamicBid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && this.isSmartBidVisible) {
            this.smartBid.reset();
            this.form.updateValueAndValidity();
          }
        }
      });

    this.getControlChanges('smartBid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && this.isDynamicBidVisible) {
            this.form.get('dynamicBid').reset();
            this.form.updateValueAndValidity();
          }
        }
      });

    if (this.roles.isManagers()) {
      this.getControlChanges('enableCustomCommission')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: value => {
            const customCommissionControl = this.form.get('customCommission');

            if (value) {
              customCommissionControl.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
              customCommissionControl.markAsTouched();
            } else {
              customCommissionControl.clearValidators();
              customCommissionControl.reset();
            }

            customCommissionControl.updateValueAndValidity();
          }
        });
    }

    this.checkBid();
  }

  /**
   * Load available user features
   * @param projectId
   */
  loadUserFeatures(projectId: string): void {
    if (!projectId) {
      return;
    }

    this.formApi
      .getUserFeatures(projectId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          if (result.status === 'OK') {
            this.handleFeatureSettings(result.data);
          }
        }
      });
  }

  private handleFeatureSettings(userFeatures: any): void {
    const { dynamicBidEnabled, campaignTTLEnabled, smartBidEnabled } = userFeatures;

    this.isDynamicBidVisible = !!dynamicBidEnabled;
    this.isCampaignTtlVisible = !!campaignTTLEnabled;
    this.isSmartBidVisible = !!smartBidEnabled;

    if (!dynamicBidEnabled) {
      this.form.removeControl('dynamicBid');
    }

    if (campaignTTLEnabled) {
      this.form.get('ttl').setValidators([Validators.min(0), Validators.max(740)]);
    }

    if (!smartBidEnabled) {
      this.form.removeControl('smartBid');
      this.form.removeControl('smartCpm');
    }

    this.form.updateValueAndValidity();
  }

  /**
   * @return {TargetItem} selected target from targetingsConfig
   */
  get selectedTarget(): TargetItem {
    return this.targetingsConfig.find(x => x.isSelected);
  }

  /**
   * Select target
   * @param {TargetItem} target
   */
  public selectTarget(target: TargetItem): void {
    if (this.selectedTarget.id === target.id) {
      return;
    }

    this.selectedTarget.isSelected = false;
    target.isSelected = true;
  }

  /**
   * Change selected in targets
   *
   * @param {string} id
   */
  public changeTarget(id: string): void {
    if (this.selectedTarget.id === id) {
      return;
    }

    const target = this.targetingsConfig.find(x => x.id === id);

    if (target) {
      this.selectTarget(target);
    }

    /** Track globica event */
    this.trackTargetGlobica('buttonAdvancedTargetKey_click', target.label);
  }

  /**
   * Generate target key for globica
   */
  public trackTargetGlobica(eventName: string, target: string): void {
    let targetKey = this.translate
      .translate(target, {}, 'en')
      .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase().split(/\s+/).join(''));

    eventName = eventName.replace('TargetKey', targetKey);
    this.globica.trackEventGoals(eventName, !this.isNewCampaign ? { campaign_id: this.campaignId.value } : null);
  }

  /**
   * Submit form
   *
   * @param submitWithCreateCreative - if user click button Create campaign and add creatives
   */
  public submit(submitWithCreateCreative?: boolean): void {
    if (!this.form.valid) {
      return;
    }

    const data = this.form.getRawValue();

    /** Track globica event */
    const globicaParams = { data };
    if (Object.keys(this.estimatorData).length) {
      Object.assign(globicaParams, this.estimatorData);
    }
    this.globica.trackEventGoals(
      this.isNewCampaign
        ? !submitWithCreateCreative
          ? 'buttonNewSaveCampaign_click'
          : 'buttonSaveNewCampaignAddCreatives_click'
        : 'buttonSaveCampaign_click',
      globicaParams
    );

    const method = this.isNewCampaign ? this.campaignService.addCampaign(data) : this.campaignService.setCampaign(data);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (response: EntityResponse) => {
        if (response.status !== 'OK') {
          this.isLoading.main = false;
          this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
          return;
        }

        if (this.isNewCampaign) {
          /** Track globica event */
          this.globica.trackEventGoals('alertCreateCampaignSuccess_show');

          if (submitWithCreateCreative) {
            this.router.navigate([APP_ROUTE.tradeDesk, DESK_ROUTE.creativeForm], {
              queryParams: { campaignId: response.id, mediaType: this.mediaType.value }
            });
          } else {
            this.router.navigate([APP_ROUTE.tradeDesk, DESK_ROUTE.campaigns]);
          }
        } else {
          this.goBack();
        }
      },
      error: () => (this.isLoading.main = false)
    });
  }

  /**
   * Go back
   * If is edit form, open camp view
   */
  public goBack(): void {
    this.navigationService.back();

    if (!this.isNewCampaign) {
      this.sidebarModal.open(CampaignViewComponent, {
        data: {
          id: this.id
        }
      });
    }
  }

  /**
   * Track globica event
   * Go back
   */
  public cancelForm(): void {
    this.globica.trackEventGoals('buttonGeneralCancel_click', !this.isNewCampaign ? { campaign_id: this.campaignId.value } : null);

    this.goBack();
  }
}
