import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { CampaignFormService } from '../../campaign-form.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import {
  BulkSelectModalResponse,
  Config,
  DynamicGridAction,
  PlacementGridData,
  PlacementItem,
  ValidateBulkSelect
} from '../../../../../../core/interface';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { BulkPopupComponent } from '../bulk-popup/bulk-popup.component';
import { CampaignFormApiService } from '../../campaign-form-api.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { environment } from '../../../../../../../environments/environment';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-spots-step',
  templateUrl: './spots-step.component.html',
  styleUrls: ['./spots-step.component.scss']
})
export class SpotsStepComponent implements OnInit {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isNotFound = false;
  public isLoading = false;
  public isBulkValidation = false;
  public targetType = [
    { id: true, value: 'label_includeSelected' },
    { id: false, value: 'label_excludeSelected' }
  ];
  public searchControl = new UntypedFormControl('');
  public loadSelected = false;

  private config: Config = environment;
  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new BehaviorSubject(null);
  private loadGrid$: Observable<PlacementGridData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.formApi.getPlacementsGrid(data))
  );

  constructor(
    public fs: CampaignFormService,
    private formApi: CampaignFormApiService,
    private modal: AdxadModal,
    private alerts: AdxadAlerts,
    private translate: TranslocoService,
    private globica: GlobicaService
  ) {}

  /**
   * @return {boolean} flag for not load placements grid if project or segments are not selected
   */
  public get isNotLoad(): boolean {
    return !this.fs.form.get('project').value || !this.fs.segments.value.length;
  }

  /**
   * Get placements targeting group from reactive form
   */
  public get group(): UntypedFormGroup {
    return this.fs.form.get('placements') as UntypedFormGroup;
  }

  /**
   * Get placements ids from form
   */
  public get placementsIds(): string[] {
    return this.group.get('value').value;
  }

  ngOnInit(): void {
    if (this.isNotLoad) {
      return;
    }

    this.paginator.init();
    this.loadGrid();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: result => {
        this.isLoading = false;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.searchControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(value => value === '' || value.length > 2),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(value => {
        /** Track globica event */
        const search = value;
        this.globica.trackEventGoals(
          'formSpotSearch_change',
          !this.fs.isNewCampaign ? { search, campaign_id: this.fs.campaignId.value } : { search }
        );
        this.loadGrid();
      });

    this.group
      .get('mode')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mode => {
        const key = mode ? 'buttonSpotInclude_click' : 'buttonSpotExclude_click';
        this.globica.trackEventGoals(key, !this.fs.isNewCampaign ? { campaign_id: this.fs.campaignId.value } : null);
      });
  }

  /**
   * Create new request in requestGrid$
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid?.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid?.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator?.limit,
      page: this.paginator?.page,
      segments: this.fs.segments?.value,
      format: this.fs.form.get('format')?.value || '',
      mediaType: this.fs.form.get('mediaType').value || '',
      search: this.searchControl.value
    };

    if (this.loadSelected) {
      request['includes'] = this.placementsIds;
    }

    this.requestGrid$.next(request);
  }

  /**
   * Load only selected placements
   * Reset paginator on 1 page
   * Load grid
   */
  changeLoadSelected(): void {
    this.globica.trackEventGoals('buttonSpotSelected_click', !this.fs.isNewCampaign ? { campaign_id: this.fs.campaignId.value } : null);
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Set spots grid
   */
  public setGrid(result: PlacementGridData): void {
    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      this.isNotFound = true;
    }

    if (this.placementsIds.length) {
      this.placementsIds.forEach(id => {
        this.dynamicGrid.patchValueById(id, true);
      });
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotFound = false;
  }

  /**
   * Check actions in grid
   * toggleElement - toggle element in form
   *
   * @param {DynamicGridAction} action
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }

    if (action.action === 'toggleElement') {
      const placement = action.value as PlacementItem;
      this.togglePlacement(placement);
    }

    if (action.action === 'toggleAll') {
      const checked = action.value.checked;
      const placements = action.value.data as PlacementItem[];

      placements
        .filter(x => (checked ? this.placementsIds.indexOf(x.id) === -1 : this.placementsIds.indexOf(x.id) !== -1))
        .forEach(x => this.togglePlacement(x));
    }
  }

  /**
   * Toggle placement in form
   * @param {PlacementItem} el
   */
  togglePlacement(el: PlacementItem): void {
    let value = [...this.placementsIds];
    if (value.find(id => el.id === id)) {
      value = value.filter(id => id !== el.id);
    } else {
      value.push(el.id);
    }
    this.group.get('value').setValue(value);
  }

  /**
   * Open mass add spots popup
   */
  openAddSpotsForm(): void {
    /** Track globica event */
    this.globica.trackEventGoals('buttonSpotsBulk_click', !this.fs.isNewCampaign ? { campaign_id: this.fs.campaignId?.value } : null);

    this.modal
      .open(BulkPopupComponent, {
        width: '588px',
        data: {
          ids: this.placementsIds,
          title: this.translate.translate('label_bulkSelectedSpots'),
          placeholder: this.translate.translate('placeholder_enterSpots'),
          hint: this.translate.translate('hint_enterAListOfNewLineSeparatedIDs'),
          value: this.fs.placements.get('value').value,
          campaignId: this.fs.campaignId?.value,
          globicaKey: 'buttonSpotBulkSave_click'
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: BulkSelectModalResponse) => {
        if (!result || !result.submit || !result.data) {
          return;
        }

        if (result.data.length) {
          this.checkBulkSelectedPlacements(result.data);
        } else {
          this.group.get('value').setValue([]);
          this.loadGrid();
        }
      });
  }

  /**
   * Validate bulk selected placements
   */
  public checkBulkSelectedPlacements(ids: string[]): void {
    this.isBulkValidation = true;

    const request = {
      ids,
      segments: this.fs.segments.value,
      format: this.fs.form.get('format')?.value || '',
      mediaType: this.fs.form.get('mediaType').value || '',
      campaignId: this.fs.campaignId?.value ? this.fs.campaignId.value : null
    };

    this.formApi
      .validateBulkSelect(`${this.config.dsp_endpoint}/placement/check`, request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: ValidateBulkSelect) => {
          this.isBulkValidation = false;

          if (status === 'OK') {
            if (data.validCount) {
              this.alerts.success(this.translate.translate('alert_addedSpots', { validCount: data.validCount }), 3000);
            }

            if (data.invalidCount) {
              this.alerts.warning(this.translate.translate('alert_IncorrectISpots', { invalidCount: data.invalidCount }), 3000);
            }

            this.group.get('value').setValue(data.data);
            this.loadGrid();
          }
        },
        error: () => {
          this.isBulkValidation = false;
        }
      });
  }
}
