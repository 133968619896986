import { NgModule } from '@angular/core';
import { GuiModule } from '../../../../gui/gui.module';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';
import { ClickUrlStepComponent } from './components/click-url-step/click-url-step.component';
import { GeneralStepComponent } from './components/general-step/general-step.component';
import { AdvancedStepComponent } from './components/advanced-step/advanced-step.component';
import { ScheduleStepComponent } from './components/schedule-step/schedule-step.component';
import { TargetingSelectComponent } from './components/targeting-select/targeting-select.component';
import { IpRangersComponent } from './components/ip-rangers/ip-rangers.component';
import { SubIdTargetComponent } from './components/sub-id-target/sub-id-target.component';
import { BulkPopupComponent } from './components/bulk-popup/bulk-popup.component';
import { CampaignFormSidebarComponent } from './components/campaign-form-sidebar/campaign-form-sidebar.component';
import { SpotsStepComponent } from './components/spots-step/spots-step.component';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { TranslocoModule } from '@jsverse/transloco';
import { PushLiveTimeComponent } from './components/push-live-time/push-live-time.component';

const ENTRY_COMPONENTS = [
  CampaignFormComponent,
  ClickUrlStepComponent,
  GeneralStepComponent,
  AdvancedStepComponent,
  ScheduleStepComponent,
  TargetingSelectComponent,
  IpRangersComponent,
  SubIdTargetComponent,
  BulkPopupComponent,
  CampaignFormSidebarComponent,
  SpotsStepComponent,
  PushLiveTimeComponent
];

@NgModule({
  imports: [GuiModule, DynamicGridModule, TranslocoModule],
  declarations: [...ENTRY_COMPONENTS],
  exports: [SubIdTargetComponent]
})
export class CampaignFormModule {}
