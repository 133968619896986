<adxad-loader
  [diameter]="40"
  class="shadow-loader"
  *ngIf="fs.isLoading.main"
></adxad-loader>

<form
  [formGroup]="fs.form"
  *ngIf="!fs.isLoading.main"
  class="campaign-form"
>
  <header class="campaign-form__header">
    <h1 class="campaign-form__title">{{ (fs.isNewCampaign ? 'createCampaign' : 'campaignSettings') | transloco }}</h1>

    <div class="campaign-form__actions">
      <ng-container *ngIf="fs.form.get('mediaType').value !== 'video'">
        <button
          (click)="fs.cancelForm()"
          adxadStrokedButton
        >
          {{ 'action_cancel' | transloco }}
        </button>

        <button
          adxadButton
          *ngIf="!fs.isNewCampaign"
          (click)="fs.submit()"
          [disabled]="isDisableSaveBtn()"
        >
          {{ 'action_saveCampaign' | transloco }}
        </button>

        <button
          adxadButton
          (click)="this.fs.submit(true)"
          [disabled]="isDisableSaveBtn()"
          *ngIf="fs.isNewCampaign"
        >
          {{ 'action_createCampaignAndAddCreatives' | transloco }}
        </button>
      </ng-container>
    </div>
  </header>

  <div class="campaign-form__body">
    <div class="campaign-form__steps">
      <div
        class="video-warning"
        *ngIf="fs.form.get('mediaType').value === 'video'"
      >
        <span class="material-icons"> warning </span>
        {{ 'notification_videoCampaignIsnTAvailableNow' | transloco }}
      </div>

      <adxad-info-block
        type="warning"
        closeBtn
        *ngIf="fs.isShowCostModelChangedInfo"
        (close)="fs.isShowCostModelChangedInfo = false"
      >
        {{ 'notification_costModelWasChanged' | transloco: { model: fs.form.get('costModel').value | uppercase } }}
      </adxad-info-block>

      <adxad-tabs-nav class="campaign-form-nav">
        <div class="campaign-form-nav__tabs">
          <adxad-tab-link
            *ngFor="let step of fs.steps"
            [class.active]="fs.activeStep === step.id"
            (click)="fs.changeStep(step.id)"
            [disabled]="fs.isDisabledStep(step.id)"
            class="no-select"
          >
            {{ step.name | transloco }}

            <adxad-tab-label-status-icon
              *ngIf="!fs.isDisabledStep(step.id)"
              [status]="getStepStatus(step)"
            ></adxad-tab-label-status-icon>
          </adxad-tab-link>
        </div>

        <div class="campaign-form-nav__btns">
          <button
            adxadStrokedButton
            class="campaign-form-nav__btn"
            [disabled]="isStepperBtnDisabled('prev')"
            (click)="fs.prevStep()"
          >
            {{ 'action_prevStep' | transloco }}
          </button>
          <button
            adxadStrokedButton
            class="campaign-form-nav__btn"
            [disabled]="isStepperBtnDisabled('next')"
            (click)="fs.nextStep()"
          >
            {{ 'action_nextStep' | transloco }}
          </button>
        </div>
      </adxad-tabs-nav>

      <adxad-general-step *ngIf="fs.activeStep === 'general'"></adxad-general-step>

      <adxad-click-url-step *ngIf="fs.activeStep === 'clickUrl'"></adxad-click-url-step>

      <adxad-advanced-step *ngIf="fs.activeStep === 'advanced'"></adxad-advanced-step>

      <adxad-spots-step *ngIf="fs.activeStep === 'placements'"></adxad-spots-step>

      <adxad-schedule-step *ngIf="fs.activeStep === 'pricing'"></adxad-schedule-step>
    </div>

    <adxad-campaign-form-sidebar class="campaign-form__sidebar"></adxad-campaign-form-sidebar>
  </div>
</form>
