<div
  class="schedule-step"
  [formGroup]="formService.form"
>
  <adxad-form-field class="form-group">
    <adxad-number-input
      placeholder="Bid"
      [disabled]="formService.isLoading.bidValidators"
      formControlName="bid"
      [prefix]="true"
      [maxlength]="7"
      [label]="formService.bidLabel | transloco"
    >
      <span class="material-icons adxad-prefix"> attach_money </span>
    </adxad-number-input>

    <adxad-hint>
      <ng-container *ngIf="this.formService?.commission"> Bid with commission {{ bidWOCommission }} | </ng-container>

      {{ 'hint_useCommaSeparator' | transloco }}
    </adxad-hint>

    <app-show-errors [control]="formService.form.get('bid')"></app-show-errors>

    <adxad-loader
      class="bid-validators-loader"
      *ngIf="formService.isLoading.bidValidators"
      [diameter]="20"
    ></adxad-loader>
  </adxad-form-field>

  <adxad-form-field class="checkbox-group">
    <adxad-checkbox
      *ngIf="formService.isDynamicBidVisible"
      formControlName="dynamicBid"
    >
      {{ 'label_dynamicBid' | transloco }}
    </adxad-checkbox>

    <adxad-checkbox
      *ngIf="formService.isSmartBidVisible"
      formControlName="smartBid"
    >
      {{ 'label_smartCpm' | transloco }}
    </adxad-checkbox>

    <adxad-checkbox
      *ngIf="roles.isManagers()"
      formControlName="enableCustomCommission"
    >
      Enable custom commission
    </adxad-checkbox>
  </adxad-form-field>

  <!-- Custom commission -->
  <adxad-form-field
    class="form-group"
    *ngIf="roles.isManagers() && formService.form.get('enableCustomCommission').value === true"
  >
    <adxad-number-input
      formControlName="customCommission"
      label="Custom commission (%)"
      placeholder="Enter custom commission"
      step="1"
    ></adxad-number-input>
    <app-show-errors [control]="formService.form.get('customCommission')"></app-show-errors>
  </adxad-form-field>
  <!-- /Custom commission -->

  <!--  Smart CPM  -->
  <div
    *ngIf="formService.smartBid?.value === true"
    class="row"
    formGroupName="smartCpm"
  >
    <adxad-form-field
      [ngClass]="formService.roles.isManagers() ? 'col-4': 'col-6'"
      class="form-group"
    >
      <adxad-select
        [options]="formService.dict.optStrategy.data"
        formControlName="optStrategy"
        label="{{ 'label_strategy' | transloco }}"
        placeholder="{{ 'placeholder_selectStrategy' | transloco }}"
      ></adxad-select>
      <app-show-errors [control]="formService.smartCpmGroup.get('optStrategy')"></app-show-errors>
    </adxad-form-field>

    <adxad-form-field
      [ngClass]="formService.roles.isManagers() ? 'col-4': 'col-6'"
      class="form-group"
    >
      <adxad-number-input
        formControlName="targetValue"
        label="{{ 'label_goalAmount' | transloco }}"
      >
      </adxad-number-input>
      <app-show-errors [control]="formService.smartCpmGroup.get('targetValue')"></app-show-errors>
    </adxad-form-field>

    <ng-container *ngIf="formService.roles.isManagers()">
      <adxad-form-field
        [ngClass]="formService.roles.isManagers() ? 'col-4': 'col-6'"
        class="form-group"
      >
        <adxad-number-input
          formControlName="testWindow"
          label="{{ 'label_testImpressions' | transloco }}"
          step="1"
        >
        </adxad-number-input>
        <app-show-errors [control]="formService.smartCpmGroup.get('testWindow')"></app-show-errors>
      </adxad-form-field>

      <adxad-form-field class="field-group col-12">
        <adxad-holders
          [holders]="formService.dict.smartCpmSegments.data"
          [isToggleAll]="true"
          class="media-types"
          formControlName="segment"
          label="Segments"
        ></adxad-holders>

        <app-show-errors [control]="formService.smartCpmGroup.get('segment')"></app-show-errors>
      </adxad-form-field>
    </ng-container>
  </div>
  <!--  End Smart CPM  -->

  <ng-container formGroupName="budget">
    <!-- Total budget -->
    <adxad-form-field class="form-group">
      <adxad-number-input
        [prefix]="true"
        formControlName="totalBudget"
        label="{{ 'label_totalBudget' | transloco }}"
      >
        <span class="material-icons adxad-prefix"> attach_money </span>
      </adxad-number-input>

      <adxad-hint> {{ 'hint_totalBudget' | transloco }} </adxad-hint>

      <app-show-errors [control]="formService.form.get('budget.totalBudget')"></app-show-errors>
    </adxad-form-field>

    <!-- Daily budget -->
    <adxad-form-field class="form-group">
      <adxad-number-input
        [prefix]="true"
        formControlName="dailyCap"
        label="{{ 'label_dailyBudget' | transloco }}"
      >
        <span class="material-icons adxad-prefix"> attach_money </span>
      </adxad-number-input>
      <app-show-errors [control]="formService.form.get('budget.dailyCap')"></app-show-errors>
    </adxad-form-field>

    <adxad-form-field class="form-group">
      <adxad-checkbox
        class="hourly-cap"
        formControlName="hourlyCapEnable"
        [disabled]="formService.form.get('budget.dailyCap').value <= 0"
      >
        <div class="hourly-cap__text">
          {{ 'label_distributeThroughoutTheDay' | transloco }}
          <adxad-help-icon
            *ngIf="formService.form.get('budget.dailyCap').value > 0"
            adxadTooltip="{{ 'tooltip_pressForBetterPerformance' | transloco }}"
          ></adxad-help-icon>
        </div>
      </adxad-checkbox>
    </adxad-form-field>
  </ng-container>

  <adxad-form-field class="form-group">
    <adxad-number-input
      formControlName="freqCap"
      label="{{ 'label_frequencyCap' | transloco }}"
      placeholder="{{ 'placeholder_enterFrequencyCap' | transloco }}"
      step="1"
    ></adxad-number-input>

    <adxad-hint> {{ getFreqCapHint() }} </adxad-hint>

    <app-show-errors [control]="formService.form.get('freqCap')"></app-show-errors>
  </adxad-form-field>

  <adxad-form-field
    class="form-group"
    *ngIf="formService.isCampaignTtlVisible"
  >
    <adxad-number-input
      formControlName="ttl"
      label="{{ 'label_campaignTtl' | transloco }}"
      placeholder="{{ 'placeholder_enterCampaignTtl' | transloco }}"
      step="1"
    ></adxad-number-input>

    <adxad-hint> {{ 'hint_campaignTtlInHours' | transloco }} </adxad-hint>
    <app-show-errors [control]="formService.form.get('ttl')"></app-show-errors>
  </adxad-form-field>

  <section class="event-schedule">
    <header class="event-schedule__header row">
      <adxad-label class="col-12"> {{ 'label_schedule' | transloco }} </adxad-label>

      <div class="col">
        <div class="event-schedule__help-tooltip">
          <div class="event-schedule__timezone">
            <span class="material-icons"> warning </span>
            <adxad-label> {{ 'label_timezoneIsUtc0' | transloco }} </adxad-label>
          </div>

          <div class="event-schedule__info">
            <div class="event-schedule__info-block">
              <div class="event-schedule__info-blue"></div>
              {{ 'label_blueActive' | transloco }};
            </div>
            <div class="event-schedule__info-block">
              <div class="event-schedule__info-gray"></div>
              {{ 'label_grayInActive' | transloco }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-auto">
        <button
          (click)="checkAll()"
          adxadStrokedButton
          class="event-schedule__select-all-btn"
          icon="done_all"
        >
          {{ (isCheckedAll ? 'action_clearAll' : 'action_selectAll') | transloco }}
        </button>
      </div>
    </header>

    <div class="row event-schedule__row">
      <div class="col event-schedule__hour-title"></div>
      <div
        *ngFor="let day of week"
        (click)="toggleScheduleValue({day: day.id})"
        [ngClass]="{'checked': isCheckedDay(day.id)}"
        (mouseenter)="toggleHoverCol(day, true)"
        (mouseleave)="toggleHoverCol(day, false)"
        class="col event-schedule__day-title"
      >
        {{ day.name }}
      </div>
    </div>
    <div
      class="row event-schedule__row"
      *ngFor="let hour of hours"
    >
      <div
        class="col event-schedule__hour-title"
        [ngClass]="{'checked': isCheckedHour(hour.id)}"
        (click)="toggleScheduleValue({hour: hour.id})"
        (mouseenter)="toggleHoverRow(hour, true)"
        (mouseleave)="toggleHoverRow(hour, false)"
      >
        {{ hour.title }}
      </div>
      <div
        class="col event-schedule__day-hour"
        *ngFor="let day of week"
        (click)="toggleScheduleValue({day:day.id, hour: hour.id})"
        [ngClass]="{'checked': isChecked(day.id, hour.id), 'hover': day.hovered || hour.hovered}"
      ></div>
    </div>
  </section>
</div>
