<div
  class="general-step"
  [formGroup]="formService.form"
>
  <adxad-form-field class="form-group">
    <adxad-input
      formControlName="name"
      label="{{ 'label_campaignName' | transloco }}"
      placeholder="{{ 'placeholder_enterName' | transloco }}"
      [autofocus]="true"
    ></adxad-input>
    <app-show-errors [control]="formService.form.get('name')"></app-show-errors>
  </adxad-form-field>

  <adxad-form-field class="form-group">
    <adxad-select
      label="{{ 'label_projectName' | transloco }}"
      formControlName="project"
      placeholder="{{ 'placeholder_selectProject' | transloco }}"
      [filterable]="true"
      [options]="formService.dict.projects.data"
      [lazy]="formService.dict.projects.data.length < formService.dict.projects.meta.total"
      (filterValue)="filterProjects($event)"
      (selectOption)="filterProjects('')"
      (scrollOnBottom)="loadProjectsDict()"
    ></adxad-select>
    <app-show-errors [control]="formService.form.get('project')"></app-show-errors>
  </adxad-form-field>

  <adxad-form-field class="form-group --media-type">
    <ng-container *ngIf="!isProjectNotSelected">
      <adxad-loader
        *ngIf="formService.isLoading.mediaType"
        [diameter]="24"
        class="media-type-loader"
      ></adxad-loader>

      <adxad-select
        [disabled]="formService.isLoading.mediaType"
        [options]="formService.dict.mediaTypes.data"
        formControlName="mediaType"
        label="{{ 'label_mediaType' | transloco }}"
        placeholder="{{ 'placeholder_selectMediaType' | transloco }}"
      ></adxad-select>
      <app-show-errors [control]="formService.form.get('mediaType')"></app-show-errors>
    </ng-container>

    <ng-container *ngIf="isProjectNotSelected">
      <adxad-label> {{ 'label_mediaType' | transloco }} </adxad-label>

      <div class="segments-warning">{{ 'notification_firstNeededSelectProject' | transloco }}</div>
    </ng-container>
  </adxad-form-field>

  <adxad-form-field
    class="form-group"
    *ngIf="isShowFormatControl"
  >
    <adxad-select
      label="{{ 'label_advertFormat' | transloco }}"
      formControlName="format"
      placeholder="{{ 'placeholder_selectFormat' | transloco }}"
      [options]="formService.dict.advertFormat.data"
    ></adxad-select>
    <app-show-errors [control]="formService.form.get('format')"></app-show-errors>
  </adxad-form-field>

  <div
    *ngIf="roles.isSuperAdmin()"
    class="row"
    formGroupName="antifraud"
  >
    <adxad-form-field class="form-group col">
      <adxad-select
        label="Redirect type"
        formControlName="redirectType"
        placeholder="Redirect type"
        [options]="formService.dict.redirectTypes.data"
      ></adxad-select>
    </adxad-form-field>

    <adxad-form-field class="form-group col">
      <adxad-label>
        Click filter type
        <adxad-help-icon adxadTooltip="Click filter type can not be 'All' or 'No JS' when Redirect Type is not 'js'"></adxad-help-icon>
      </adxad-label>
      <adxad-select
        formControlName="clickFilterType"
        placeholder="Click filter type"
        [options]="formService.dict.clickFilterTypes.data"
      ></adxad-select>
    </adxad-form-field>

    <adxad-form-field
      *ngIf="isShowBannerRedirect"
      class="form-group col"
    >
      <adxad-select
        label="Banner redirect"
        formControlName="bannerRedirect"
        placeholder="Banner redirect"
        [options]="formService.dict.bannerRedirect.data"
      ></adxad-select>
    </adxad-form-field>

    <adxad-form-field
      *ngIf="isShowBannerRedirect"
      class="form-group col"
    >
      <adxad-select
        label="Banner filter type"
        formControlName="bannerFilterType"
        placeholder="Banner filter type"
        [options]="formService.dict.bannerFilterTypes.data"
      ></adxad-select>
    </adxad-form-field>
  </div>

  <adxad-form-field
    class="form-group"
    [formGroup]="formService.segments"
  >
    <adxad-label> {{ 'label_segments' | transloco }} </adxad-label>

    <div
      *ngIf="isProjectNotSelected"
      class="segments-warning"
    >
      {{ 'notification_firstNeededSelectProject' | transloco }}
    </div>

    <ng-container *ngIf="!isProjectNotSelected">
      <adxad-loader
        *ngIf="formService.isLoading.segments"
        [diameter]="24"
        class="segments-loader"
      ></adxad-loader>

      <div
        *ngIf="formService.archivedSegments.length"
        class="segments-warning"
      >
        <div
          (click)="formService.archivedSegments = []"
          class="segments-warning__close"
        >
          <span class="material-icons">close</span>
        </div>
        <div class="segments-warning__title">
          <span class="material-icons">warning</span>

          {{ 'label_theseSegmentsWasDisabled' | transloco }}:
        </div>

        <div
          *ngFor="let segment of formService.archivedSegments"
          class="segments-warning__item"
        >
          {{ segment.label }}
        </div>
      </div>

      <ul
        *transloco="let segmentsGroup"
        class="segments"
      >
        <li *ngFor="let segmentGroup of segmentsGroups">
          <div
            (click)="toggleAllSegmentsGroup(segmentGroup)"
            class="adxad-checkbox"
          >
            <div
              [class.checked]="isAllSegments(segmentGroup)"
              [class.indeterminate]="isIndeterminateSegments(segmentGroup)"
              class="adxad-checkbox__icon"
            >
              <div
                [class.open]="isAllSegments(segmentGroup) || isIndeterminateSegments(segmentGroup)"
                class="adxad-checkbox__icon-bg"
              ></div>
              <span
                *ngIf="isAllSegments(segmentGroup) || isIndeterminateSegments(segmentGroup)"
                class="material-icons"
              >
                {{ isAllSegments(segmentGroup) ? 'check' : 'remove' }}
              </span>
            </div>
            <span class="adxad-checkbox__text"> {{ segmentsGroup('segments__group-' + segmentGroup.id) }} </span>
          </div>

          <ul
            *transloco="let segmentTranslate"
            class="segments__sub-list"
          >
            <li
              *ngFor="let segment of getSegments(segmentGroup.id)"
              class="segments__sub-list-item"
            >
              <div
                (click)="changeSegment(segment, segmentGroup)"
                class="adxad-checkbox"
              >
                <div
                  [class.checked]="isCheckedSegment(segment.id)"
                  class="adxad-checkbox__icon"
                >
                  <div
                    [class.open]="isCheckedSegment(segment.id)"
                    class="adxad-checkbox__icon-bg"
                  ></div>
                  <span
                    *ngIf="isCheckedSegment(segment.id)"
                    class="material-icons"
                  >
                    check
                  </span>
                </div>

                <span class="adxad-checkbox__text"> {{ segmentTranslate('segment__' + segment.id) }} </span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </ng-container>
  </adxad-form-field>
</div>
