@if (!formService.isLoading.blackList && !formService.isLoading.cashDicts) {
<div
  class="row no-gutters"
  *transloco="let t;"
>
  <div class="col-4">
    <div class="advanced-nav">
      @for (advance of formService.targetingsConfig; track advance) { @if (isShowTarget(advance)) {
      <div
        class="advanced-nav__item"
        [ngClass]="{active: advance.id === formService.selectedTarget.id}"
        (click)="formService.changeTarget(advance.id)"
      >
        {{ t(advance.label) }} @if (getAdvancedLength(advance)) {
        <div class="advanced-nav__count">{{ getAdvancedLength(advance) }}</div>
        }
      </div>
      } }
    </div>
  </div>

  @if (formService.selectedTarget) {
  <div class="col-8">
    <app-targeting-select [group]="targetingGroup(formService.selectedTarget.id)" />
  </div>
  }
</div>
} @if (formService.isLoading.blackList) {
<div class="black-list-loader">
  <adxad-loader [diameter]="40" />
</div>
}
