<div class="ip-control__header">
  <div class="row">
    <adxad-form-field class="col ip-control__field">
      <adxad-input
        placeholder="{{ 'placeholder_addIp' | transloco }}"
        maxlength="31"
        [(ngModel)]="ipValue"
        [class.invalid]="!isValidIpRange()"
        (keypress)="keyboardListener($event)"
      ></adxad-input>

      <adxad-hint> {{ 'hint_specificIpRanges' | transloco }} </adxad-hint>
    </adxad-form-field>

    <div class="col-auto">
      <button
        (click)="add()"
        adxadButton
      >
        {{ 'action_add' | transloco }}
      </button>
    </div>

    <div class="col-auto">
      <button
        (click)="openPopup()"
        adxadStrokedButton
      >
        {{ 'action_bulkSelectedIPs' | transloco }}
      </button>
    </div>
  </div>

  <ng-container *ngIf="control.value?.length">
    <div class="row">
      <adxad-label class="col"> {{ 'label_selected' | transloco }} </adxad-label>

      <div
        (click)="clearAll()"
        class="col-auto ip-control__clear-btn"
      >
        {{ 'action_clearAll' | transloco }}
      </div>
    </div>

    <div class="ip-control__search">
      <span class="material-icons ip-control__search-icon">search</span>

      <input
        class="ip-control__search-field"
        placeholder="{{ 'placeholder_search' | transloco }}"
        [(ngModel)]="filterSelectedValue"
      />
    </div>
  </ng-container>
</div>

<div
  class="ip-control__list"
  *ngIf="control.value?.length"
>
  <div
    class="ip-control__list-item"
    *ngFor="let value of filteredValue; let i = index"
  >
    {{ value }}
    <span
      (click)="remove(value)"
      class="material-icons"
      >close</span
    >
  </div>
</div>
