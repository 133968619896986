import { Component, DestroyRef, inject, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { BulkPopupComponent } from '../bulk-popup/bulk-popup.component';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { CampaignFormService } from '../../campaign-form.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-ip-rangers',
  templateUrl: './ip-rangers.component.html',
  styleUrls: ['./ip-rangers.component.scss']
})
export class IpRangersComponent {
  @Input() group: UntypedFormGroup;
  ipValue = '';
  filterSelectedValue = '';

  private destroyRef = inject(DestroyRef);

  constructor(
    private alerts: AdxadAlerts,
    private modal: AdxadModal,
    private translate: TranslocoService,
    private globica: GlobicaService,
    public formService: CampaignFormService
  ) {}

  get control(): UntypedFormControl {
    return this.group.get('value') as UntypedFormControl;
  }

  /**
   * @return {string[]} filtered array from control
   */
  get filteredValue(): string[] {
    return this.control.value.filter(x => x.toLowerCase().indexOf(this.filterSelectedValue.toLowerCase()) !== -1);
  }

  /**
   * Validation of ip
   * @param {string} value
   */
  isValidIpRange(value?: string): boolean {
    if (!value) {
      value = this.ipValue;
    }
    value = value.trim();

    // eslint-disable-next-line max-len
    const IP1_REGEX =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    // eslint-disable-next-line max-len
    const IP2_REGEX =
      /(^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$)|(^([0-2][0-9]{0,1}|3[0-2]){1}$)/;
    let isValid = false;

    if (value.indexOf('/') !== -1) {
      const arr = value.split('/');

      if (arr.length > 2) {
        return false;
      }

      isValid = IP1_REGEX.test(arr[0]) && IP2_REGEX.test(arr[1]);
    } else {
      isValid = IP1_REGEX.test(value);
    }

    return isValid;
  }

  /**
   * Add ip in control
   */
  add(): void {
    if (!this.isValidIpRange()) {
      this.alerts.error(this.translate.translate('alert_ipIsNotValid'), 2000);
      return;
    }

    const value = this.control.value ?? [];

    if (value.find(x => x === this.ipValue)) {
      this.alerts.warning(this.translate.translate('alert_thisValueIsAlreadyExist'), 2000);
      return;
    }

    value.push(this.ipValue);
    this.control.setValue(value);
    this.ipValue = '';

    /** Track globica event */
    this.globica.trackEventGoals(
      'buttonIPsAdd_click',
      !this.formService.isNewCampaign ? { campaign_id: this.formService.campaignId.value } : null
    );
  }

  /**
   * Remove value from control
   * @param {string} value
   */
  remove(value: string): void {
    const index = this.control.value.findIndex(x => x === value);
    this.control.value.splice(index, 1);
  }

  /**
   * Clear control
   */
  clearAll(): void {
    this.globica.trackEventGoals('buttonIPsClearAll_click');
    this.control.setValue([]);
  }

  /**
   * Keyboard listener on input
   */
  keyboardListener(e): void {
    if (e.code === 'Enter') {
      e.preventDefault();
      this.add();
    }
  }

  /**
   * Open mass add ips popup
   */
  openPopup(): void {
    /** Track globica event */
    this.globica.trackEventGoals(
      'buttonIPsBulk_click',
      !this.formService.isNewCampaign ? { campaign_id: this.formService.campaignId?.value } : null
    );

    this.modal
      .open(BulkPopupComponent, {
        width: '588px',
        data: {
          id: 'ip',
          title: this.translate.translate('label_bulkSelectedIPs'),
          placeholder: this.translate.translate('placeholder_enterIPs'),
          description: this.translate.translate('notification_enterAListOfNewLineSeparatedIPs'),
          hint: this.translate.translate('hint_targetOrBlockSpecificIpRanges'),
          value: this.control.value,
          campaignId: this.formService.campaignId?.value,
          globicaKey: 'buttonIPsBulkSave_click'
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (!result || !result.submit || !result.data) {
          return;
        }

        if (result.data.length) {
          const value = [...new Set(result.data)] as string[];
          const validIPs = value.filter(ip => this.isValidIpRange(ip)).map(ip => ip.trim());
          this.control.setValue(validIPs);

          let message = this.translate.translate('alert_AddedIPs', { validIPs: validIPs.length });

          if (validIPs.length !== result.data.length) {
            message = this.translate.translate('alert_IncorrectIPs', { validIPs: result.data.length - validIPs.length });
            this.alerts.error(message, 3000);
          } else {
            this.alerts.success(message, 3000);
          }
        } else {
          this.control.setValue([]);
        }
      });
  }
}
