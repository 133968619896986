<div
  class="bulk-validation-loader"
  *ngIf="isBulkValidation"
>
  <adxad-loader [diameter]="40"></adxad-loader>
</div>

<div
  class="project-select-warning"
  *ngIf="isNotLoad"
>
  {{ 'notification_firstNeededSelectProjectAndSegments' | transloco }}
</div>

<div
  class="row placements-step-toolbar"
  *ngIf="!isNotLoad"
>
  <adxad-radio-button
    (changeValue)="this.group.get('mode').setValue($event)"
    [options]="targetType"
    [value]="group.get('mode').value"
    [disabled]="isNotFound"
    [translate]="true"
    class="col-auto"
  ></adxad-radio-button>

  <adxad-form-field class="col">
    <adxad-input
      [formControl]="searchControl"
      [suffix]="true"
      placeholder="{{ 'placeholder_search' | transloco }}"
    >
      <span
        class="material-icons adxad-suffix search__clear-btn"
        *ngIf="searchControl.value.length"
        (click)="searchControl.setValue('')"
      >
        close
      </span>
    </adxad-input>
  </adxad-form-field>

  <div class="col-auto">
    <adxad-checkbox
      (changeChecked)="changeLoadSelected()"
      [(ngModel)]="loadSelected"
      [disabled]="!placementsIds.length"
    >
      {{ 'label_selectedSpots' | transloco }}
    </adxad-checkbox>
  </div>

  <div class="col-auto">
    <button
      (click)="openAddSpotsForm()"
      adxadStrokedButton
    >
      {{ 'action_bulkSelectedSpots' | transloco }}
    </button>
  </div>
</div>

<adxad-grid-not-found
  *ngIf="isNotFound"
  [showClearFilters]="false"
></adxad-grid-not-found>

<adxad-dynamic-grid
  (reload)="loadGrid()"
  (action)="gridAction($event)"
  [loading]="isLoading"
  [class.hide]="isNotLoad"
  class="placements-grid"
></adxad-dynamic-grid>

<adxad-paginator
  (changePage)="loadGrid()"
  [disabled]="isLoading"
  [class.hide]="isNotLoad"
  view="short"
></adxad-paginator>
