import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdxadValidators } from '../../../../../../core/validators';
import { merge } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { PushLiveTimeValidityService } from './push-live-time-validity.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-push-live-time',
  templateUrl: './push-live-time.component.html',
  styleUrls: ['./push-live-time.component.scss']
})
export class PushLiveTimeComponent implements OnInit {
  @Input({ required: true })
  group: FormGroup;

  form = new FormGroup(
    {
      min: this.fb.control(null, [Validators.min(0), Validators.max(72), AdxadValidators.number]),
      max: this.fb.control(null, [Validators.min(0), Validators.max(72), AdxadValidators.number])
    },
    { validators: AdxadValidators.minMax }
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private validationService: PushLiveTimeValidityService
  ) {}

  ngOnInit(): void {
    const minControl = this.form.get('min');
    const maxControl = this.form.get('max');
    const groupValues = this.group.get('value').value;

    if (groupValues && groupValues.length === 2) {
      minControl.setValue(groupValues[0], { emitEvent: false });
      maxControl.setValue(groupValues[1], { emitEvent: false });
    }

    merge(
      minControl.valueChanges.pipe(
        distinctUntilChanged(),
        tap(value => {
          if (value === '') {
            minControl.setValue(null, { emitEvent: false });
            minControl.updateValueAndValidity();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      ),
      maxControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
    ).subscribe(() => {
      this.group.patchValue(
        {
          value: [minControl.value, maxControl.value]
        },
        { emitEvent: false }
      );
    });

    this.form.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: status => {
        const isInValid = status === 'INVALID';
        this.validationService.isPushLiveTimeValid$.next(isInValid);
      }
    });
  }

  reset(): void {
    this.form.reset();
    this.group.patchValue({ value: [] });
  }
}
