import { Component, ElementRef, ViewChild } from '@angular/core';
import { Dict } from '../../../../../../core/interface';
import { CampaignFormService } from '../../campaign-form.service';
import { GlobicaService } from '../../../../../../core/services/globica.service';

@Component({
  selector: 'adxad-click-url-step',
  templateUrl: './click-url-step.component.html',
  styleUrls: ['./click-url-step.component.scss']
})
export class ClickUrlStepComponent {
  @ViewChild('urlClick') urlClick: ElementRef;

  private cursorPosition = 0;

  constructor(
    public formService: CampaignFormService,
    private globica: GlobicaService
  ) {
    if (this.formService.clickUrl.value?.length) {
      this.cursorPosition = this.formService.clickUrl.value.length;
    }
  }

  /**
   * Set cursor position on blur field
   *
   * @param {FocusEvent} e
   */
  setCursorPosition(e: FocusEvent): void {
    this.cursorPosition = e.target['selectionStart'];
  }

  /**
   * Add placeholder in click url
   * or replace all same holders
   *
   * @param holder
   */
  toggleHolder(holder: Dict): void {
    let code = this.formService.clickUrl.value;

    if (code.indexOf(holder.id) !== -1) {
      const re = new RegExp(holder.id, 'g');
      code = code.replace(re, '');
    } else {
      code = code.slice(0, this.cursorPosition) + holder.id + code.slice(this.cursorPosition);
    }

    /** Track globica event */
    const globicaParams = {
      holder: holder.value
    };

    if (!this.formService.isNewCampaign) {
      globicaParams['campaign_id'] = this.formService.campaignId.value;
    }
    this.globica.trackEventGoals('buttonHolder_click', globicaParams);

    this.formService.clickUrl.setValue(code);
  }

  /**
   * @param selected
   * @return array filtered holders
   */
  getHolders(selected?: boolean): Dict[] {
    return this.formService.dict.holders.data.filter(x => (selected ? x.selected : !x.selected));
  }
}
