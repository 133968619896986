<div class="campaign-sidebar">
  <div class="estimator">
    <h3>{{ 'estimatedAmountOfDailyTraffic' | transloco }}</h3>

    <div class="estimator__impressions">
      {{ winRate === null ? '&mdash;' : (winRate | number: '1.0-0') }}

      <button
        adxadStrokedButton
        [disabled]="isLoadingEstimate"
        (click)="refreshEstimation()"
      >
        <adxad-loader
          class="submit-loader"
          [diameter]="20"
          *ngIf="isLoadingEstimate"
        ></adxad-loader>

        <span [ngClass]="{'loading': isLoadingEstimate}"> {{ 'action_estimate' | transloco }} </span>
      </button>
    </div>

    <adxad-hint> {{ 'hint_estimateTheAmountOfTraffic' | transloco }} </adxad-hint>
  </div>

  <div
    *ngIf="isViewRecommendedBids"
    class="recommended-bids"
  >
    <h3 class="recommended-bids__title">
      {{ 'label_recommendedBids' | transloco }}

      <adxad-help-icon adxadTooltip="{{ 'tooltip_theReachAmountAndRecommendedBids' | transloco }}"></adxad-help-icon>
    </h3>

    <section class="row">
      <article
        class="col-4 recommended-bids__item"
        *ngIf="recommendedBids.hasOwnProperty('maxCpm')"
      >
        <adxad-label class="recommended-bids__label"> {{ 'label_maximum' | transloco }} </adxad-label>
        <div
          class="recommended-bids__value"
          [class.-clicked]="formService.activeStep === 'pricing'"
          (click)="setBid(recommendedBids.maxCpm)"
        >
          ${{ recommendedBids.maxCpm }}
        </div>
      </article>

      <article
        class="col-4 recommended-bids__item"
        *ngIf="recommendedBids.hasOwnProperty('avgCpm')"
      >
        <adxad-label class="recommended-bids__label"> {{ 'label_medium' | transloco }} </adxad-label>
        <div
          class="recommended-bids__value"
          [class.-clicked]="formService.activeStep === 'pricing'"
          (click)="setBid(recommendedBids.avgCpm)"
        >
          ${{ recommendedBids.avgCpm }}
        </div>
      </article>

      <article
        class="col-4 recommended-bids__item"
        *ngIf="recommendedBids.hasOwnProperty('minCpm')"
      >
        <adxad-label class="recommended-bids__label"> {{ 'label_minimum' | transloco }} </adxad-label>
        <div
          class="recommended-bids__value"
          [class.-clicked]="formService.activeStep === 'pricing'"
          (click)="setBid(recommendedBids.minCpm)"
        >
          ${{ recommendedBids.minCpm }}
        </div>
      </article>

      <!--   Temporarily hide this block by task (https://jira.jhdvmedia.com/browse/DSP-4848)   -->
      <ng-container *ngIf="false">
        <article
          class="col-4 recommended-bids__item"
          *ngIf="recommendedBids.hasOwnProperty('q15Cpm')"
        >
          <adxad-label class="recommended-bids__label"> q15 </adxad-label>
          <div
            class="recommended-bids__value"
            [class.-clicked]="formService.activeStep === 'pricing'"
            (click)="setBid(recommendedBids.q15Cpm)"
          >
            ${{ recommendedBids.q15Cpm }}
          </div>
        </article>

        <article
          class="col-4 recommended-bids__item"
          *ngIf="recommendedBids.hasOwnProperty('q25Cpm')"
        >
          <adxad-label class="recommended-bids__label"> q25 </adxad-label>
          <div
            class="recommended-bids__value"
            [class.-clicked]="formService.activeStep === 'pricing'"
            (click)="setBid(recommendedBids.q25Cpm)"
          >
            ${{ recommendedBids.q25Cpm }}
          </div>
        </article>

        <article
          class="col-4 recommended-bids__item"
          *ngIf="recommendedBids.hasOwnProperty('q50Cpm')"
        >
          <adxad-label class="recommended-bids__label"> q50 </adxad-label>
          <div
            class="recommended-bids__value"
            [class.-clicked]="formService.activeStep === 'pricing'"
            (click)="setBid(recommendedBids.q50Cpm)"
          >
            ${{ recommendedBids.q50Cpm }}
          </div>
        </article>

        <article
          class="col-4 recommended-bids__item"
          *ngIf="recommendedBids.hasOwnProperty('q75Cpm')"
        >
          <adxad-label class="recommended-bids__label"> q75 </adxad-label>
          <div
            class="recommended-bids__value"
            [class.-clicked]="formService.activeStep === 'pricing'"
            (click)="setBid(recommendedBids.q75Cpm)"
          >
            ${{ recommendedBids.q75Cpm }}
          </div>
        </article>

        <article
          class="col-4 recommended-bids__item"
          *ngIf="recommendedBids.hasOwnProperty('q85Cpm')"
        >
          <adxad-label class="recommended-bids__label"> q85 </adxad-label>
          <div
            class="recommended-bids__value"
            [class.-clicked]="formService.activeStep === 'pricing'"
            (click)="setBid(recommendedBids.q85Cpm)"
          >
            ${{ recommendedBids.q85Cpm }}
          </div>
        </article>

        <article
          class="col-4 recommended-bids__item"
          *ngIf="recommendedBids.hasOwnProperty('q99Cpm')"
        >
          <adxad-label class="recommended-bids__label"> q95 </adxad-label>
          <div
            class="recommended-bids__value"
            [class.-clicked]="formService.activeStep === 'pricing'"
            (click)="setBid(recommendedBids.q99Cpm)"
          >
            ${{ recommendedBids.q99Cpm }}
          </div>
        </article>
      </ng-container>
      <!--   End temporarily hide this block by task (https://jira.jhdvmedia.com/browse/DSP-4848)   -->
    </section>
  </div>

  <div class="campaign-sidebar-block">
    <h3>{{ 'costModel' | transloco }}</h3>

    <div class="campaign-sidebar-block__value">{{ formService.form.get('costModel').value | uppercase }}</div>
  </div>

  <div
    class="placements campaign-sidebar-block"
    *ngIf="isShowPlacements"
  >
    <h3>{{ 'spots' | transloco }}</h3>

    <div
      class="campaign-sidebar-block__link"
      (click)="goToPlacements()"
    >
      {{ formService.placements.get('mode').value ? 'include' : 'exclude' }}

      <span class="campaign-sidebar-block__count"> {{ formService.placements.get('value').value?.length ?? 0 }} </span>
    </div>
  </div>

  <div class="advanced">
    <h3>{{ 'advanced' | transloco }}</h3>

    <div class="advanced__targets">
      <ng-container *ngFor="let item of formService.targetingsConfig">
        <div
          class="advanced__target"
          (click)="goToTarget(item)"
          *ngIf="isShowTarget(item)"
        >
          <div class="advanced__target-label">{{ item.label | transloco }}</div>

          <div class="advanced__target-link">
            <ng-container *ngIf="item.type !== 'range'; else rangeTemplate">
              <span class="advanced__target-mode"> {{ getTargetMode(item) | lowercase }} </span>

              <span class="advanced__target-count"> {{ getControlValue(item.id)?.length ?? 0 }} </span>
            </ng-container>

            <ng-template #rangeTemplate>
              <div class="advanced__target-range">
                <div
                  class="min"
                  *ngIf="getControlValue(item.id)[0] !== null"
                >
                  {{ 'label_minimum' | transloco }}:
                  <span class="advanced__target-count"> {{ getControlValue(item.id)[0] }} </span>
                </div>

                <div
                  class="max"
                  *ngIf="getControlValue(item.id)[1] !== null"
                >
                  {{ 'label_maximum' | transloco }}:
                  <span class="advanced__target-count"> {{ getControlValue(item.id)[1] }} </span>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
