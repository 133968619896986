<ng-container
  *transloco="let t"
  [formGroup]="form"
>
  <adxad-form-field class="field-group">
    <adxad-input
      formControlName="min"
      [label]="t('label_minimum')"
      [autofocus]="true"
    ></adxad-input>
    <app-show-errors [control]="form.get('min')"></app-show-errors>
  </adxad-form-field>

  <adxad-form-field class="field-group">
    <adxad-input
      formControlName="max"
      [label]="t('label_maximum')"
    ></adxad-input>
    <app-show-errors [control]="form.get('max')"></app-show-errors>
  </adxad-form-field>

  <button
    (click)="reset()"
    adxadStrokedButton
    [iconHoverColor]="'error'"
    [icon]="'delete_outline'"
    [disabled]="!form.get('min').value || !form.get('max').value"
  >
    {{ t('action_clear') }}
  </button>
</ng-container>
