import { Component, OnInit } from '@angular/core';
import { CampaignFormService } from '../../campaign-form.service';
import { ScheduleDay, ScheduleHour } from '../../../../../../core/interface';
import { UntypedFormControl } from '@angular/forms';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'adxad-schedule-step',
  templateUrl: './schedule-step.component.html',
  styleUrls: ['./schedule-step.component.scss']
})
export class ScheduleStepComponent implements OnInit {
  public week = [
    { name: 'Mon', id: 'mon', hovered: false },
    { name: 'Tue', id: 'tue', hovered: false },
    { name: 'Wed', id: 'wed', hovered: false },
    { name: 'Thu', id: 'thu', hovered: false },
    { name: 'Fri', id: 'fri', hovered: false },
    { name: 'Sat', id: 'sat', hovered: false },
    { name: 'Sun', id: 'sun', hovered: false }
  ] as ScheduleDay[];
  public hours: ScheduleHour[];

  private value;

  constructor(
    public formService: CampaignFormService,
    private globica: GlobicaService,
    public roles: RolesService,
    private transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.value = this.formService.form.get('schedule').value;
    this.hours = new Array(24);

    Array.apply(null, { length: 24 }).map((x, i) => {
      const title = (i < 10 ? '0' + i : i) + ':00';
      this.hours[i] = { id: i, title: title };
    });

    this.bid.markAsTouched();
  }

  /**
   * @return {boolean} all schedule is checked
   */
  public get isCheckedAll(): boolean {
    const keys = Object.keys(this.value);
    if (keys.length !== 7) {
      return false;
    }

    let isChecked = true;
    keys.forEach(key => {
      if (this.value[key].length !== 24) {
        isChecked = false;
      }
    });

    return isChecked;
  }

  /**
   * Get bid without commission
   */
  public get bidWOCommission(): string {
    const result = this.bid.value - (this.bid.value * this.formService.commission) / 100;
    return '$' + +result.toFixed(4);
  }

  /**
   * @return {UntypedFormControl} bid reactive form control
   */
  private get bid(): UntypedFormControl {
    return this.formService.form.get('bid') as UntypedFormControl;
  }

  /**
   * @return {boolean} day column is checked
   * @param {string} day
   */
  public isCheckedDay(day: string): boolean {
    if (day === undefined) {
      return false;
    }

    return this.value[day] && this.value[day].length === 24;
  }

  /**
   * @return {boolean} hour row is checked
   * @param {number} hour
   */
  public isCheckedHour(hour: number): boolean {
    if (hour === undefined || Object.keys(this.value).length < 7) {
      return false;
    }

    let isSame = true;
    Object.values(this.value).forEach((value: number[]) => {
      if (value.find(x => x === hour) === undefined) {
        isSame = false;
        return false;
      }
    });
    return isSame;
  }

  /**
   * @return {boolean} day-hour cell is checked
   * @param {string} day
   * @param {string} hour
   */
  public isChecked(day: string, hour: number): boolean {
    if (day === undefined && hour === undefined) {
      return false;
    }

    return this.value[day] && this.value[day].indexOf(hour) !== -1;
  }

  /**
   * Toggle day column, hour row or day-hour cell
   * @param date
   */
  public toggleScheduleValue(date: { day?: string; hour?: number }) {
    if (date.day === undefined && date.hour === undefined) {
      return;
    }
    if (date.day === undefined) {
      this.toggleHour(date.hour);
    } else if (date.hour === undefined) {
      this.toggleDay(date.day);
    } else {
      this.toggleDayHour(date.day, date.hour);
    }

    /** Track globica event */
    this.globica.trackEventGoals(
      'formCampaignSchedule_change',
      !this.formService.isNewCampaign ? { campaign_id: this.formService.campaignId?.value, date } : { date }
    );
  }

  /**
   * Toggle schedule element
   * @param {string} day
   * @param {number} hour
   */
  private toggleDayHour(day: string, hour: number) {
    if (this.value[day] !== undefined) {
      const idx = this.value[day].indexOf(hour);
      if (idx !== -1) {
        /* delete hour */
        this.value[day].splice(idx, 1);
        if (!this.value[day].length) {
          this.value[day] = [];
        }
      } else {
        /* add hour */
        this.value[day].push(hour);
      }
    } else {
      this.value[day] = [hour];
    }
  }

  /**
   * Toggle all hour in day
   * @param day
   */
  private toggleDay(day: string) {
    if (this.value[day] && this.value[day].length === 24) {
      this.value[day] = [];
    } else {
      this.value[day] = this.hours.map(hour => hour.id);
    }
  }

  /**
   * Toggle hour in all days
   * @param hour
   */
  private toggleHour(hour: number) {
    let isAdd = false;
    this.week.forEach(day => {
      if (!this.value[day.id] || this.value[day.id].indexOf(hour) === -1) {
        isAdd = true;
      }
    });

    this.week.forEach(day => {
      if (isAdd) {
        if (!this.value[day.id]) {
          this.value[day.id] = [hour];
        } else if (this.value[day.id].indexOf(hour) === -1) {
          this.value[day.id].push(hour);
        }
      } else {
        const i = this.value[day.id].indexOf(hour);
        this.value[day.id].splice(i, 1);
      }
    });
  }

  /**
   * Toggle hovered day col
   * Need for highlight col
   * @param {ScheduleDay} day
   * @param {boolean} isHover
   */
  public toggleHoverCol(day: ScheduleDay, isHover: boolean): void {
    day.hovered = isHover;
  }

  /**
   * Toggle hovered hour row
   * Need for highlight row
   * @param {ScheduleHour} hour
   * @param {boolean} isHover
   */
  public toggleHoverRow(hour: ScheduleHour, isHover: boolean): void {
    hour.hovered = isHover;
  }

  /**
   * Toggle check all schedule
   */
  public checkAll(): void {
    const checkAll = !this.isCheckedAll;
    this.value = { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] };

    if (checkAll) {
      this.week.forEach(day => this.toggleDay(day.id));
    }

    /** Track globica event */
    this.globica.trackEventGoals(
      checkAll ? 'buttonScheduleSelectAll_click' : 'buttonScheduleClearAll_click',
      !this.formService.isNewCampaign ? { campaign_id: this.formService.campaignId?.value } : null
    );

    this.formService.form.get('schedule').setValue(this.value);
  }

  public getFreqCapHint(): string {
    const costModelControl = this.formService.form.get('costModel').value;
    const mediaTypeControl = this.formService.form.get('mediaType').value;

    const hints = {
      push: 'hint_impressionsFromAUniqueUserPer',
      cpc: 'hint_clicksFromAUniqueUserPer',
      default: 'hint_clicksFromAUniqueUserPer'
    };

    const hintKey = mediaTypeControl === 'push' ? hints.push : costModelControl === 'cpc' ? hints.cpc : hints.default;
    return this.transloco.translate(hintKey);
  }
}
