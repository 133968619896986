<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ (config.data.title ? config.data.title : 'modal_titleBulkSelected') | transloco }}</h2>

    <p *ngIf="config.data.description">{{ config.data.description ? config.data.description : '' }}</p>
  </adxad-modal-header>

  <adxad-modal-content>
    <p
      *ngIf="config.data.invalidData"
      class="rejected"
    >
      {{ 'label_rejected' | transloco: {counter: config.data.invalidData.length} }}
    </p>

    <adxad-textarea
      [(ngModel)]="value"
      [placeholder]="(config.data.placeholder ? config.data.placeholder : '') | transloco"
      [autofocus]="true"
    >
    </adxad-textarea>

    <adxad-hint *ngIf="config.data.hint"> {{ config.data.hint | transloco }} </adxad-hint>

    <p
      *ngIf="config.data.invalidData"
      class="accepted"
    >
      {{ 'label_successfullyAdded' | transloco: {counter: config.data.value.length} }}
    </p>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        (click)="close()"
        adxadStrokedButton
        class="w100"
      >
        {{ 'action_cancel' | transloco }}
      </button>
    </div>

    <div class="col">
      <button
        (click)="submit()"
        adxadButton
        class="w100"
      >
        {{ 'action_save' | transloco }}
      </button>
    </div>
  </adxad-modal-actions>
</ng-container>
