import { Component, DestroyRef, ElementRef, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  BulkSelectModalResponse,
  Dict,
  LoadTargetingDictConfig,
  TargetItem,
  TargetType,
  ValidateBulkSelect
} from '../../../../../../core/interface';
import { CampaignFormService } from '../../campaign-form.service';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { DspService } from '../../../../../../core/services/dsp.service';
import { BulkPopupComponent } from '../bulk-popup/bulk-popup.component';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { CampaignFormApiService } from '../../campaign-form-api.service';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { DictsService } from '../../../../../../core/services/dicts.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-targeting-select',
  templateUrl: './targeting-select.component.html',
  styleUrls: ['./targeting-select.component.scss']
})
export class TargetingSelectComponent implements OnInit, OnChanges {
  @Input() group: UntypedFormGroup;
  @ViewChild('scrollWrapper') scrollWrapper: ElementRef;

  public availableSearchControl = new UntypedFormControl();
  public selectedSearchControl = new UntypedFormControl();
  public targetType = [
    { id: true, value: 'label_includeSelected' },
    { id: false, value: 'label_excludeSelected' }
  ];
  public isLoading = false;

  private dictsSubcr = {
    available: null,
    selected: null
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private dictsService: DictsService,
    private dspService: DspService,
    public formService: CampaignFormService,
    private formApi: CampaignFormApiService,
    private modal: AdxadModal,
    private alerts: AdxadAlerts,
    private translate: TranslocoService,
    private globica: GlobicaService
  ) {}

  /**
   * @return {UntypedFormControl} form control
   */
  get control(): UntypedFormControl {
    return this.group.get('value') as UntypedFormControl;
  }

  /**
   * @return {TargetItem} selected target
   */
  get target(): TargetItem {
    return this.formService.selectedTarget;
  }

  /**
   * @return {TargetType} type of selected target
   */
  get type(): TargetType {
    return this.target.type;
  }

  /**
   * @return {boolean} show warning for Browsers, Countries, Devices, Mobile carriers, OS targetings & Spots
   */
  get isShowWarning(): boolean {
    const id = this.target.id;
    return (
      !this.control.value.length &&
      (id === 'browsers' || id === 'countries' || id === 'devices' || id === 'carriers' || id === 'os' || id === 'spots')
    );
  }

  /**
   * @return {boolean} flag to show available loader
   */
  get isAvailableLoader(): boolean {
    const availableDict = this.target.dicts.available;
    return availableDict.isLoading || availableDict.isLazy;
  }

  /**
   * @return {boolean} flag to show selected loader
   */
  get isSelectedLoader(): boolean {
    const selectedDict = this.target.dicts.selected;
    return selectedDict.isLoading || selectedDict.isLazy;
  }

  ngOnInit(): void {
    if (this.target.type === 'dict') {
      /** Load filtered available dict */
      this.availableSearchControl.valueChanges
        .pipe(skip(1), debounceTime(500), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
        .subscribe(value => {
          const data = {
            value: value,
            isNewSearch: true,
            type: 'available'
          } as LoadTargetingDictConfig;

          this.loadTargetingsDict(data);
        });

      /** Load filtered selected dict */
      this.selectedSearchControl.valueChanges
        .pipe(skip(1), debounceTime(500), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
        .subscribe(value => {
          if (!this.control.value.length) {
            return;
          }

          const data = {
            value: value,
            isNewSearch: true,
            type: 'selected'
          } as LoadTargetingDictConfig;

          this.loadTargetingsDict(data);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.group) {
      this.reset();
    }
  }

  /**
   * Reset component
   * Clear filter values
   * Scroll on top
   * reset includes
   * load dicts
   */
  reset(): void {
    this.availableSearchControl.setValue('');
    this.selectedSearchControl.setValue('');

    if (this.scrollWrapper) {
      this.scrollWrapper.nativeElement.scrollTop = 0;
    }

    /** set excludes & includes in targets */
    if (this.target.type === 'dict') {
      this.target.dicts.available.dict = { data: [] };
      this.target.dicts.selected.dict = { data: [] };
    }

    this.loadDicts();
  }

  /**
   * @return {Dict[]} selected items list
   */
  get selected(): Dict[] {
    if (!this.target) {
      return [];
    }

    return this.target.dicts.selected.dict.data;
  }

  /**
   * @return {Dict[]} filtered list of available targets
   */
  get available(): Dict[] {
    if (!this.target || !this.target.dicts.available.dict) {
      return [];
    }

    return this.target.dicts.available.dict.data;
  }

  /**
   * @return {boolean} flat to disable clear all btn
   */
  get isDisabledClearBtn(): boolean {
    return !this.target.dicts.selected.dict.data.length;
  }

  /**
   * @return {boolean} flat to disable select all btn
   */
  get isDisabledSelectBtn(): boolean {
    return !this.target.dicts.available.dict.data.length;
  }

  /**
   * Load available & selected dicts
   */
  loadDicts(): void {
    if (!this.target.dicts) {
      return;
    }

    const data = {
      value: this.availableSearchControl.value,
      isNewSearch: true,
      type: 'available'
    } as LoadTargetingDictConfig;

    this.loadTargetingsDict(data);

    if (this.control.value.length) {
      const data = {
        value: this.selectedSearchControl.value,
        isNewSearch: true,
        type: 'selected'
      } as LoadTargetingDictConfig;

      this.loadTargetingsDict(data);
    }
  }

  /**
   * Select targeting
   * Push id in reactive form control
   * @param {Dict} targeting
   */
  add(targeting: Dict): void {
    if (!this.control.value) {
      this.control.setValue([]);
    }

    this.formService.isTargetChanged[this.target.id] = true;

    this.control.value.unshift(targeting.id);
    this.target.dicts.selected.dict.data.unshift(targeting);
    this.target.dicts.available.dict.data = this.target.dicts.available.dict.data.filter(x => x.id !== targeting.id);
  }

  /**
   * Remove selected targeting
   * @param {Dict} targeting
   */
  remove(targeting: Dict): void {
    this.formService.isTargetChanged[this.target.id] = true;

    const i = this.control.value.indexOf(targeting.id);
    this.control.value.splice(i, 1);

    this.target.dicts.selected.dict.data = this.target.dicts.selected.dict.data.filter(x => x.id !== targeting.id);
    this.target.dicts.available.dict.data.unshift(targeting);
  }

  /**
   * Select all targetings
   * If dict is lazy, load next page
   */
  selectAll(): void {
    if (this.isDisabledSelectBtn) {
      return;
    }

    this.available.forEach(x => this.add(x));

    if (this.target.dicts.available.isLazy) {
      const data = {
        value: this.availableSearchControl.value,
        isNewSearch: false,
        type: 'available'
      } as LoadTargetingDictConfig;

      this.loadTargetingsDict(data);
    }

    /** Track globica event */
    this.formService.trackTargetGlobica('buttonTargetKeySelectAll_click', this.target.label);
  }

  /**
   * Clear all targetings
   * If dict is lazy, load next page
   */
  clearAll(): void {
    if (this.isDisabledClearBtn) {
      return;
    }

    this.selected.forEach(x => this.remove(x));

    if (this.target.dicts.selected.isLazy) {
      const data = {
        value: this.selectedSearchControl.value,
        isNewSearch: true,
        type: 'selected'
      } as LoadTargetingDictConfig;

      this.loadTargetingsDict(data);
    }

    /** Track globica event */
    this.formService.trackTargetGlobica('buttonTargetKeyClearAll_click', this.target.label);
  }

  /**
   * Detect scroll for lazy load
   * @param {Event} e
   * @param {string} type of dict: selected or available
   */
  scrollTargets(e: Event, type: 'available' | 'selected'): void {
    if (!this.target.dicts[type].isLazy) {
      return;
    }

    const target = e.target as HTMLElement;
    if (Math.ceil(target.scrollTop + target.offsetHeight) >= target.scrollHeight) {
      const value = type === 'available' ? this.availableSearchControl.value : this.selectedSearchControl.value;
      this.target.dicts[type].isLazy = true;
      const data = {
        value: value,
        isNewSearch: false,
        type: type
      } as LoadTargetingDictConfig;

      this.loadTargetingsDict(data);
    }
  }

  /**
   * Open bulk select modal
   * @param invalidData
   */
  openBulkSelectedDialog(invalidData?: string[]): void {
    let data = {};
    let globicaKey = '';

    if (invalidData?.length) {
      data['invalidData'] = invalidData;
    }

    if (!this.formService.isNewCampaign) {
      data['campaignId'] = this.formService.campaignId.value;
    }

    const targetId = this.target.id;

    switch (targetId) {
      case 'spots':
        globicaKey = 'buttonSitesBulk_click';
        break;
      case 'countries':
        globicaKey = 'buttonCountriesBulk_click';
        break;
      case 'ssp':
        globicaKey = 'buttonSspBulk_click';
        break;
      case 'domains':
        globicaKey = 'buttonDomainsBulk_click';
        break;
      case 'city':
        globicaKey = 'buttonCityBulk_click';
        break;
      case 'keywords':
        globicaKey = 'buttonKeywordsBulk_click';
        break;
    }

    /** Track globica event */
    this.globica.trackEventGoals(globicaKey, !this.formService.isNewCampaign ? { campaign_id: this.formService.campaignId.value } : null);

    this.modal
      .open(BulkPopupComponent, {
        width: '588px',
        data: {
          ...data,
          ...this.target.subId.bulkSelectedPopupConfig,
          ...this.formService.targetings.get(targetId).value
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: BulkSelectModalResponse) => {
        if (!result || !result.submit || !result.data) {
          return;
        }

        if (result.data.length) {
          if (targetId === 'domains' || targetId === 'keywords' || targetId === 'city') {
            const value = [...new Set(result.data)];
            this.control.setValue(value);
          } else {
            this.checkMassAddIds(result.data);
          }
        } else {
          this.control.setValue([]);
          this.reset();
        }
      });
  }

  /**
   * Check ids from dict
   *
   * @param {string[]} ids
   */
  checkMassAddIds(ids: string[]): void {
    this.isLoading = true;
    const request = { ids };
    const targetId = this.target.id;

    /** If active target Sites */
    if (targetId === 'spots') {
      request['segments'] = this.formService.segments.value;
      request['campaignId'] = this.formService.campaignId?.value ? this.formService.campaignId.value : null;
    }

    this.formApi
      .validateBulkSelect(this.formService.selectedTarget.bulkValidate, request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: ValidateBulkSelect) => {
          this.isLoading = false;

          if (status === 'OK') {
            if (data.validCount && !data.invalidCount) {
              this.alerts.success(
                this.translate.translate(this.target.subId.bulkSelectedPopupConfig.alertSuccess, { validCount: data.validCount }),
                3000
              );
            }
            this.control.setValue(data.data);

            if (data.invalidCount) {
              this.openBulkSelectedDialog(data.invalidData);
            }
            this.reset();
          }
        },
        error: () => (this.isLoading = false)
      });
  }

  isActionBulkSelectedView(): boolean {
    return (
      this.target.id === 'spots' ||
      this.target.id === 'ssp' ||
      this.target.id === 'countries' ||
      this.target.id === 'domains' ||
      this.target.id === 'city' ||
      this.target.id === 'keywords'
    );
  }

  /**
   * Tracking the change for globica
   */
  targetModeChange() {
    /** Track globica event */
    const key = this.group.get('mode').value ? 'buttonTargetKeyInclude_click' : 'buttonTargetKeyExclude_click';
    this.formService.trackTargetGlobica(key, this.target.label);
  }

  /**
   * Load filtered dict
   * if is lazy, push result to option
   * If is new request, ignore isLoading
   *
   * @param {string} value - value for filter dict
   * @param {boolean} isNewSearch
   * @param {string} type of dict; available or selected
   */
  private loadTargetingsDict({ value, isNewSearch, type }: LoadTargetingDictConfig): void {
    if (!this.target.url) {
      return;
    }

    /** reset includes & excludes */
    this.target.dicts.available.excludes = [...this.control.value];
    this.target.dicts.selected.includes = [...this.control.value];
    const dict = this.target.dicts[type];

    if (isNewSearch) {
      dict.dict.data = [];
    } else {
      if (dict.isLoading) {
        return;
      }
    }
    dict.isLoading = true;

    const cashId = this.target.dicts.cashId;
    const request = {
      limit: cashId ? 500 : 200,
      page: !isNewSearch && dict.isLazy && dict.dict?.meta ? dict.dict.meta.current_page + 1 : 1,
      search: value
    };

    if (type === 'available') {
      request['excludes'] = dict.excludes;
    } else {
      request['includes'] = dict.includes;
    }

    if (this.target.id === 'spots') {
      request['segments'] = this.formService.segments.value;
    }

    const method =
      this.target.dicts.cashId && this.dictsService.isCashed(cashId)
        ? this.dictsService.getDict$(cashId, request)
        : this.dspService.getDictByUrl(this.target.url, request);

    this.dictsSubcr[type] = method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: ({ data, meta }) => {
        dict.isLoading = false;
        dict.dict.data = isNewSearch || !dict.isLazy ? data : dict.dict.data.concat(data);

        if (meta) {
          dict.dict.meta = meta;

          dict.isLazy = this.target.id === 'spots' && meta.isLazy !== undefined ? meta.isLazy : meta.total_pages > meta.current_page;
        } else {
          dict.isLazy = false;
        }

        if (cashId) {
          this.dictsService.setDict(cashId, { data, meta });
        }
      },
      error: () => {
        dict.isLoading = false;
        dict.isLazy = false;
      }
    });
  }
}
