<div
  class="row"
  [formGroup]="formService.form"
>
  <div class="col-12 offset-xl-3 col-xl-6">
    <adxad-form-field class="form-group">
      <adxad-textarea
        label="{{ 'label_clickUrl' | transloco }}"
        placeholder="{{ 'placeholder_pasteClickUrlHere' | transloco }}"
        formControlName="clickUrl"
        (blurEvent)="setCursorPosition($event)"
        [fixWidth]="true"
      ></adxad-textarea>
      <app-show-errors [control]="formService.clickUrl"></app-show-errors>
    </adxad-form-field>

    <adxad-holders
      class="creatives-form__holders"
      *ngIf="formService.dict?.holders"
      [holders]="formService.dict.holders.data"
      [text]="formService.clickUrl.value"
      (changeHolder)="toggleHolder($event)"
      label="{{ 'label_holders' | transloco }}"
    ></adxad-holders>
  </div>
</div>
