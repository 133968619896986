import { Component, OnInit } from '@angular/core';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { BulkSelectModalResponse } from '../../../../../../core/interface';

@Component({
  selector: 'adxad-bulk-popup',
  templateUrl: './bulk-popup.component.html',
  styleUrls: ['./bulk-popup.component.scss']
})
export class BulkPopupComponent implements OnInit {
  public value = '';
  public isLoading = false;
  private cacheData = [];

  constructor(
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private globica: GlobicaService
  ) {}

  ngOnInit(): void {
    /**
     * This.value used for show in textarea
     * Cached data used for save valid values
     */
    if (this.config.data.invalidData?.length) {
      this.cacheData = [...this.config.data.value];
      this.value = this.config.data.invalidData.join('\n');
    } else {
      this.value = this.config.data?.value.join('\n');
    }
  }

  /**
   * Submit form
   */
  submit(): void {
    /** Track globica event */
    this.globica.trackEventGoals(
      this.config.data.globicaKey,
      this.config.data.campaignId ? { campaign_id: this.config.data.campaignId } : null
    );

    let ids = this.value
      .split('\n')
      .map(x => x.trim())
      .filter(String);
    ids = ids.concat(this.cacheData);
    this.modalRef.close({ submit: true, data: ids } as BulkSelectModalResponse);
  }

  /**
   * Close modal
   */
  close(): void {
    this.cacheData = [];
    this.modalRef.close();
  }
}
