<div
  class="targeting-loader"
  *ngIf="isLoading"
>
  <adxad-loader [diameter]="40"></adxad-loader>
</div>

<header class="targeting-header">
  <section
    class="row no-gutters targeting-header__top"
    *ngIf="target.type !== 'range'"
  >
    <div class="col targeting-header__target-type">
      <adxad-radio-button
        (changeValue)="this.group.get('mode').setValue($event)"
        [options]="targetType"
        [value]="group.get('mode').value"
        [translate]="true"
        (click)="targetModeChange()"
      ></adxad-radio-button>
    </div>
    <div class="col-auto">
      <button
        (click)="openBulkSelectedDialog()"
        *ngIf="isActionBulkSelectedView()"
        adxadStrokedButton
      >
        {{ target.subId.actionBulkSelected | transloco }}
      </button>
    </div>
  </section>

  <section
    class="targeting-header__warning"
    *ngIf="isShowWarning"
  >
    {{ 'notification_itemsAreSelectedAutomatically' | transloco }}
  </section>
</header>

<!-- dict -->
<section
  class="targeting-list row no-gutters"
  *ngIf="type === 'dict'"
>
  <div class="col-6 targeting-list__col">
    <div class="targeting-list__header">
      <div class="row no-gutters">
        <div class="col targeting-list__title">{{ 'label_available' | transloco }}</div>

        <div
          class="col-auto targeting-list__action targeting-list__action_select"
          [class.disabled]="isDisabledSelectBtn"
          (click)="selectAll()"
        >
          {{ 'action_selectAll' | transloco }}
        </div>

        <div class="col-12 targeting-list__search">
          <span class="material-icons targeting-list__search-icon"> search </span>
          <input
            class="targeting-list__search-field"
            [formControl]="availableSearchControl"
            placeholder="{{ 'placeholder_search' | transloco }}"
          />
        </div>
      </div>
    </div>

    <div class="targeting-list__body">
      <div
        #scrollWrapper
        (scroll)="scrollTargets($event, 'available')"
        class="targeting-list__list-wrapper"
      >
        <div
          *ngFor="let available of available"
          class="targeting-list__item targeting-list__item_available"
          (click)="add(available)"
        >
          {{available.value}}
          <span class="material-icons"> check </span>
        </div>
        <adxad-loader
          class="targeting-list__loader"
          *ngIf="isAvailableLoader"
          [diameter]="24"
        ></adxad-loader>
      </div>
    </div>
  </div>

  <div class="col-6 targeting-list__col">
    <div class="targeting-list__header">
      <div class="row no-gutters">
        <div class="col targeting-list__title">{{ 'label_selected' | transloco }}</div>

        <div
          class="col-auto targeting-list__action targeting-list__action_clear"
          [class.disabled]="isDisabledClearBtn"
          (click)="clearAll()"
        >
          {{ 'action_clearAll' | transloco }}
        </div>

        <div class="col-12 targeting-list__search">
          <span class="material-icons targeting-list__search-icon"> search </span>
          <input
            class="targeting-list__search-field"
            [formControl]="selectedSearchControl"
            placeholder="{{ 'placeholder_search' | transloco }}"
          />
        </div>
      </div>
    </div>

    <div class="targeting-list__body">
      <div
        class="targeting-list__list-wrapper"
        (scroll)="scrollTargets($event, 'selected')"
      >
        <div
          *ngFor="let selected of selected"
          class="targeting-list__item targeting-list__item_select"
          (click)="remove(selected)"
        >
          {{ selected.value }}
          <span class="material-icons">close</span>
        </div>

        <adxad-loader
          class="targeting-list__loader"
          *ngIf="isSelectedLoader"
          [diameter]="24"
        ></adxad-loader>
      </div>
    </div>
  </div>
</section>

<!-- textarea -->
<section
  *ngIf="this.type === 'subId'"
  class="targeting-list"
>
  <adxad-sub-id-target
    [group]="group"
    [target]="target?.subId"
  ></adxad-sub-id-target>
</section>

<!-- ip ranges -->
<section
  class="targeting-list"
  *ngIf="type === 'ip'"
>
  <app-ip-rangers [group]="group"></app-ip-rangers>
</section>

<!-- Push live time -->
<section
  class="targeting-list"
  *ngIf="type === 'range'"
>
  <adxad-push-live-time [group]="group"></adxad-push-live-time>
</section>
