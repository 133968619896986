import { Component, DestroyRef, inject } from '@angular/core';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { DspService } from '../../../../../../core/services/dsp.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { CampaignFormService } from '../../campaign-form.service';
import { Segment, SegmentGroup } from '../../../../../../core/interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-general-step',
  templateUrl: './general-step.component.html',
  styleUrls: ['./general-step.component.scss']
})
export class GeneralStepComponent {
  private searchValue = '';
  private destroyRef = inject(DestroyRef);

  constructor(
    public formService: CampaignFormService,
    private alerts: AdxadAlerts,
    private dspService: DspService,
    public roles: RolesService,
    private translate: TranslocoService,
    private globica: GlobicaService
  ) {}

  /**
   * @return {boolean} show for super admins if mediaType is native, banner or push
   */
  get isShowBannerRedirect(): boolean {
    if (!this.roles.isSuperAdmin()) {
      return false;
    }

    const value = this.formService.form.get('mediaType').value;
    const allowedValues = ['image', 'push', 'native', 'vast'];
    return allowedValues.includes(value) && !!value;
  }

  /**
   * @return {boolean} show format control
   */
  get isShowFormatControl(): boolean {
    const allowedValues = ['image', 'html'];
    const value = this.formService.form.get('mediaType').value;
    return allowedValues.includes(value) && !!value;
  }

  /**
   * @return {boolean} show warning for admins & managers if project not selected
   */
  get isProjectNotSelected(): boolean {
    return !this.formService.form.get('project').value;
  }

  /**
   * @return {UntypedFormGroup} segments groups from config
   */
  get antifraud(): UntypedFormGroup {
    return this.formService.form.get('antifraud') as UntypedFormGroup;
  }

  /**
   * @return segments groups from config
   */
  get segmentsGroups(): SegmentGroup[] {
    return this.formService.segmentsConfig.data;
  }

  /**
   * @return active segments of group
   * @param id of group
   */
  getSegments(id: string): Segment[] {
    return this.segmentsGroups.find(group => group.id === id).segments.filter(segment => segment.status !== 0);
  }

  /**
   * @return {UntypedFormControl} project control
   */
  get projectControl(): UntypedFormControl {
    return this.formService.form.get('project') as UntypedFormControl;
  }

  /**
   * Load projects dict
   */
  loadProjectsDict(): void {
    const dict = this.formService.dict.projects;
    if (dict.isLoading) {
      return;
    }

    dict.isLoading = true;

    const request: any = {
      limit: 200,
      page: dict.isLazy && dict.meta ? dict.meta.current_page + 1 : 1
    };

    if (this.projectControl.value) {
      request['ids'] = [this.projectControl.value];
    }

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    this.dspService
      .getProjectsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;

          dict.data = this.formService.dict.projects.data.concat(result.data);

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => (dict.isLoading = false)
      });
  }

  /**
   * Load filtered projects dict
   *
   * @param {string} value
   */
  filterProjects(value: string): void {
    if (this.formService.form.get('project').disabled) {
      return;
    }

    const dict = this.formService.dict.projects;
    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;
    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value
    };

    if (this.projectControl.value) {
      request['ids'] = [this.projectControl.value];
    }

    this.dspService
      .getProjectsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;

          dict.data = result.data;

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => (dict.isLoading = false)
      });
  }

  /**
   * @return{boolean} flag of part all segments in group is ON
   * @param {SegmentGroup} group
   */
  isAllSegments(group: SegmentGroup): boolean {
    return this.getSegments(group.id).every(segment => this.formService.segments.value.indexOf(segment.id) !== -1);
  }

  /**
   * @return {boolean} flag of part of segments in group is ON
   * @param {SegmentGroup} group
   */
  isIndeterminateSegments(group: SegmentGroup): boolean {
    const values = this.getSegments(group.id).filter(segment => this.formService.segments.value.indexOf(segment.id) !== -1);
    return values.length && values.length !== group.segments.length;
  }

  /**
   * Change all member area traffic segments
   *
   * @param {SegmentGroup} segmentGroup
   */
  toggleAllSegmentsGroup(segmentGroup: SegmentGroup): void {
    const value = segmentGroup.segments.filter((segment: Segment) => segment.status !== 0).map((segment: Segment) => segment.id);

    this.formService.segments.setValue(value);
    this.formService.setCostModel(segmentGroup);
  }

  /**
   * One of segments checkbox must be ON
   * Reset placements
   *
   * @param {Segment} segment
   * @param {SegmentGroup} segmentGroup - segments group
   */
  changeSegment(segment: Segment, segmentGroup: SegmentGroup): void {
    if (segment.status == 0) {
      return;
    }

    this.formService.removePlacements();

    const checked = !this.isCheckedSegment(segment.id);
    let value = [...this.formService.segments.value];

    if (checked) {
      /** Add checked segment to control */

      /** check change segments group */
      const isChangeSegment = !!this.segmentsGroups
        .filter(group => group.id !== segmentGroup.id)
        .find(group => {
          return group.segments.find((segment: Segment) => {
            return value.indexOf(segment.id) !== -1;
          });
        });

      if (isChangeSegment) {
        value = [];
        this.formService.setCostModel(segmentGroup);
      }

      if (value.indexOf(segment.id) === -1) {
        value.push(segment.id);
      }

      /** Track globica event */
      const globicaParams = {
        segments: [value]
      };

      if (!this.formService.isNewCampaign) {
        globicaParams['campaign_id'] = this.formService.campaignId.value;
      }
      this.globica.trackEventGoals('checkboxSegment_check', globicaParams);
    } else {
      /** Remove segment from control */
      if (value.length === 1) {
        this.alerts.warning(this.translate.translate('alert_youShouldChooseAtLeastOneOfTheOfferedTypes'), 3000);
        return;
      }

      value = value.filter(id => segment.id !== id);
    }

    this.formService.segments.setValue(value);
  }

  /**
   * @return {boolean} flag of checked control
   * @param {string} id
   */
  isCheckedSegment(id: string): boolean {
    return this.formService.segments.value.indexOf(id) !== -1;
  }
}
