import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CampaignFormSettingsResponse, Config, ItemView, PlacementGridData, ValidateBulkSelect } from '../../../../core/interface';
import { DspService, Request } from '../../../../core/services/dsp.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampaignFormApiService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private dspService: DspService
  ) {}

  /**
   * @param data
   * @return {TargetsConfig} placements grid
   */
  getPlacementsGrid(data): Observable<PlacementGridData> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<PlacementGridData>(`${this.config.dsp_endpoint}/placement/grid`, data, { headers });
  }

  /**
   * Validate bulk selected values
   * @param {string} url
   * @param data
   */
  validateBulkSelect(url: string, data): Observable<ValidateBulkSelect> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<ValidateBulkSelect>(url, data, { headers });
  }

  /**
   * Get settings for create form: finances, targetings, segments
   * @param data
   */
  getNewFormSettings(data = {}): Observable<CampaignFormSettingsResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<CampaignFormSettingsResponse>(`${this.config.dsp_endpoint}/campaign/settings`, data, { headers });
  }

  /**
   * Get settings for edit form: finances, targetings, segments
   * @param request
   */
  getEditFormSettings(request?: Request): Observable<CampaignFormSettingsResponse> {
    return this.http.get<CampaignFormSettingsResponse>(`${this.config.dsp_endpoint}/campaign/full/get`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Get available user features
   * @param projectId
   */
  getUserFeatures(projectId: string): Observable<ItemView<string>> {
    return this.http.get<ItemView<string>>(`${this.config.dsp_endpoint}/project/features?id=${projectId}`);
  }
}
