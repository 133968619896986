import { Component, DestroyRef, inject, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { BulkPopupComponent } from '../bulk-popup/bulk-popup.component';
import { BulkSelectModalResponse, TargetItemSubId } from '../../../../../../core/interface';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-sub-id-target',
  templateUrl: './sub-id-target.component.html',
  styleUrls: ['./sub-id-target.component.scss']
})
export class SubIdTargetComponent {
  @Input()
  group: UntypedFormGroup;

  @Input()
  target: TargetItemSubId;

  public inputValue = '';
  public filterSelectedValue = '';

  private destroyRef = inject(DestroyRef);

  constructor(
    private alerts: AdxadAlerts,
    private modal: AdxadModal,
    private translate: TranslocoService
  ) {}

  /**
   * @return {UntypedFormControl} value form control
   */
  get control(): UntypedFormControl {
    return this.group.get('value') as UntypedFormControl;
  }

  /**
   * @return {boolean} flag of disable add btn
   */
  get disabledAddBtn(): boolean {
    return !this.inputValue.trim().length;
  }

  /**
   * @return {string[]} filtered array from control
   */
  get filteredValue(): string[] {
    return this.control.value.filter(x => x.toLowerCase().indexOf(this.filterSelectedValue.toLowerCase()) !== -1);
  }

  /**
   * Add ip in control
   */
  add(): void {
    const value = this.control.value ? this.control.value : [];
    const id = this.inputValue.trim();

    if (value.find(x => x === id)) {
      this.alerts.warning(this.translate.translate('alert_thisValueIsAlreadyExist'), 2000);
      return;
    }

    value.push(id);
    this.control.setValue(value);
    this.inputValue = '';
  }

  /**
   * Remove value from control
   * @param {string} value
   */
  remove(value: string): void {
    const index = this.control.value.findIndex(x => x === value);
    this.control.value.splice(index, 1);
  }

  /**
   * Clear control
   */
  clearAll(): void {
    this.control.setValue([]);
  }

  /**
   * Keyboard listener on input
   */
  keyboardListener(e): void {
    if (e.code === 'Enter') {
      e.preventDefault();
      this.add();
    }
  }

  /**
   * Open mass add ips popup
   */
  openPopup(): void {
    this.modal
      .open(BulkPopupComponent, {
        width: '588px',
        data: {
          id: 'ip',
          title: this.translate.translate('label_bulkSelectedIDs'),
          placeholder: this.translate.translate('placeholder_enterIDs'),
          hint: this.translate.translate('hint_enterAListOfNewLineSeparatedIDs'),
          value: this.control.value
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: BulkSelectModalResponse) => {
        if (result && result.submit && result.data) {
          if (result.data?.length) {
            const value = [...new Set(result.data)]; // clear doubles
            this.control.setValue(value);
            this.alerts.info(this.translate.translate('alert_addedIds', { value: value.length }), 3000);
          } else {
            this.control.setValue([]);
          }
        }
      });
  }
}
