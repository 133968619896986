import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CampaignFormService } from '../../campaign-form.service';
import { TargetItem } from '../../../../../../core/interface';

@Component({
  selector: 'adxad-advanced-step',
  templateUrl: './advanced-step.component.html',
  styleUrls: ['./advanced-step.component.scss']
})
export class AdvancedStepComponent implements OnInit {
  constructor(public formService: CampaignFormService) {}

  ngOnInit() {
    if (!this.isShowTarget(this.formService.selectedTarget)) {
      const firstSowsTarget = this.formService.targetingsConfig.find((target: TargetItem) => this.isShowTarget(target));
      this.formService.selectTarget(firstSowsTarget);
    }
  }

  /**
   * @return {boolean} flag of show target by segment
   * @param {TargetItem} target
   */
  isShowTarget(target: TargetItem): boolean {
    if (!target.rule) {
      return true;
    }

    const checkRules = Object.entries(target.rule).filter(([key, rule]) => {
      const control = this.formService.form.get(key);
      if (!control) {
        return false;
      }

      const value = control.value;

      if (Array.isArray(rule)) {
        /** If rule is array, show target if one of rules exist in control value */
        return !!rule.find(x => value.indexOf(x) !== -1);
      }

      return value.indexOf(rule) !== -1;
    });

    return !!checkRules.length;
  }

  /**
   * Count selected targetings
   * @param {TargetItem} target
   */
  getAdvancedLength(target: TargetItem): number {
    const group = this.targetingGroup(target.id);
    if (!group) return 0;

    const value = group.get('value')?.value;

    if (target.type === 'range') {
      return value.length ? 1 : 0;
    }

    if (Array.isArray(value)) {
      return value.length;
    }

    if (typeof value === 'string') {
      return target.type === 'subId' ? value.split('\n').filter(Boolean).length : value.length;
    }

    return 0;
  }

  /**
   * @param {string} name of targeting
   * @return {UntypedFormGroup} reactive form group of selected targeting
   */
  targetingGroup(name: string): UntypedFormGroup {
    return this.formService.targetings.get(name) as UntypedFormGroup;
  }
}
